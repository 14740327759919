import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { Contentful_BlockTwoCircleImage, Contentful_ImageWithFocalPoint, Maybe } from "graphql-types";
import useColourway from "../../hooks/useColourway";
import Image from "../image";
import gsap from "gsap";
import { PropsWithLocation } from "../../utils/types";

const Circle = ({
  children,
  position,
  color,
  isTransparent,
}: {
  children: React.ReactNode;
  position: "left" | "right";
  color: string | null;
  isTransparent: boolean | null | undefined;
}) => {
  const positionClass = classnames([
    {
      "-translate-x-12 -translate-y-4 md:-translate-x-32 md:-translate-y-20 lg:-translate-x-40 lg:-translate-y-16":
        position === "left",
      "translate-x-16 translate-y-16 md:translate-x-32 md:translate-y-24 lg:translate-x-48 lg:translate-y-24":
        position === "right",
    },
  ]);

  const primaryImageBubble = useRef(null);
  const primaryImageContainer = useRef(null);
  const primaryImageBackgroundAsset = useRef(null);
  const primaryImageBackground = useRef(null);

  const foregroundContainer = useRef(null);
  const foregroundBubble = useRef(null);

  const rotate = (cx: number, cy: number, x: number, y: number, angle: number) => {
    let radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return { x: nx, y: ny };
  };

  const animation: any = {
    angle: 0,
  };

  useEffect(() => {
    if (position == "left") {
      gsap.fromTo(primaryImageContainer.current, { scale: 0 }, { scale: 1, duration: 1, ease: "power3.out", delay: 0 });
      gsap.fromTo(primaryImageBackground.current, { scale: 3 }, { scale: 1, duration: 1, ease: "power3.out", delay: 0 });
    } else {
      gsap.fromTo(primaryImageContainer.current, { scale: 0 }, { scale: 1, duration: 1, ease: "power3.out", delay: 0.1 });
      gsap.fromTo(primaryImageBackground.current, { scale: 3 }, { scale: 1, duration: 1, ease: "power3.out", delay: 0.1 });
    }

    gsap.fromTo(
      animation,
      { angle: 0 },
      {
        angle: 360,
        duration: 30,
        repeat: -1,
        ease: "none",
        onUpdate: () => {
          let currentRotation;
          if (position == "left") {
            currentRotation = rotate(0, 0, 10, 10, animation.angle + -90);
            gsap.set(primaryImageBubble.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
            });
            currentRotation = rotate(0, 0, 20, 20, animation.angle + 90);
            gsap.set(primaryImageBackgroundAsset.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
            });
          } else {
            currentRotation = rotate(0, 0, 10, 10, animation.angle + 90);
            gsap.set(primaryImageBubble.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
            });
            currentRotation = rotate(0, 0, 20, 20, animation.angle - 90);
            gsap.set(primaryImageBackgroundAsset.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
            });
          }

          currentRotation = rotate(0, 0, 20, 20, animation.angle - 90);
          gsap.set(foregroundBubble.current, {
            x: currentRotation.x,
            y: currentRotation.y,
            z: 0,
          });
        },
      }
    );

    return () => {
      gsap.killTweensOf([
        primaryImageContainer.current,
        primaryImageBackground.current,
        foregroundContainer.current,
        primaryImageBubble.current,
        foregroundBubble.current,
        animation,
      ]);
    };
  });

  return (
    <>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative" ref={primaryImageBubble}>
          <div ref={primaryImageContainer} className="relative">
            <div
              className={`relative sectors-carousel-hero-${position} rounded-full overflow-hidden inline-block bg-cover bg-center transform ${positionClass}`}
            >
              <div ref={primaryImageBackground} className="absolute top-0 left-0 w-full h-full">
                <div ref={primaryImageBackgroundAsset} className="absolute sectors-carousel-hero-image">
                  {children}
                </div>
              </div>

              <div
                className={`relative sectors-carousel-hero-${position}-overlay inline-block sectors-carousel-overlay-offset-1`}
              >
                <div ref={foregroundBubble} className="absolute top-0 left-0 w-full h-full">
                  <div
                    ref={foregroundContainer}
                    className={`absolute top-0 left-0 w-full h-full overflow-hidden rounded-full  ${
                      !isTransparent && `bg-${color}-400`
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TwoCircleImage = (props: PropsWithLocation<Contentful_BlockTwoCircleImage>) => {
  const { tailwindClassName } = useColourway(props.colourway || null);

  const leftImage: Maybe<Contentful_ImageWithFocalPoint> =
    (props.imagesCollection?.items && props.imagesCollection?.items[0]) ?? null;

  const rightImage: Maybe<Contentful_ImageWithFocalPoint> =
    (props.imagesCollection?.items && props.imagesCollection?.items[1]) ?? null;

  return (
    <section className={`container relative mx-auto h-full px-6 py-6 md:py-12 z-10 text-center`}>
      <div className="relative block w-full sectors-carousel-hero">
        <Circle isTransparent={props.transparent} position="left" color={tailwindClassName}>
          {leftImage && leftImage.image && (
            <Image
              image={leftImage.image}
              alt={leftImage.image.title ?? ""}
              maxWidth={1200}
              type={"fluid"}
              loading="lazy"
              className="h-full w-full"
            />
          )}
        </Circle>
        <Circle isTransparent={props.transparent} position="right" color={tailwindClassName}>
          {rightImage && rightImage.image && (
            <Image
              image={rightImage.image}
              alt={rightImage.image.title ?? ""}
              maxWidth={1200}
              type={"fluid"}
              loading="lazy"
              className="h-full w-full"
            />
          )}
        </Circle>
      </div>
    </section>
  );
};

export default TwoCircleImage;
