import React from "react";
import classnames from "classnames";

function Paragraph({ className, children }: { className: string; children: React.ReactNode }) {
  return <p className={classnames(["mb-4", "last:mb-0", className])}>{children}</p>;
}

Paragraph.defaultProps = {
  className: "",
};

// export default Paragraph;
export default React.memo(Paragraph);
