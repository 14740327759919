import React, { useEffect, useRef } from "react";
import SVG from "react-inlinesvg";
import { Contentful_Article, Contentful_ResearchArea, Contentful_Sector } from "graphql-types";
import useTree, { CollectionType } from "../../../hooks/useTree";
import { CollectionItem } from "../../article";
import Heading3 from "../../rich-text-elements/heading-3";
import Paragraph from "../../rich-text-elements/paragraph";
import Button from "../../button";
import Image from "../../image";

function QuickViewContents({
  visible,
  quickViewId,
  articles,
  setQuickViewId,
  contentTypes,
}: {
  visible: boolean;
  quickViewId: string | null;
  articles: Contentful_Article[];
  setQuickViewId: any;
  contentTypes: any;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const item: Contentful_Article | any = articles.find((article: any) => article.sys.id === quickViewId);

  useEffect(() => {
    if (visible && item) {
      ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [visible, quickViewId, item]);

  if (!item) return null;

  const contentType = contentTypes.items.find(
    (contentType: any) =>
      contentType.sys.id === item.contentType.sys.id ||
      contentType.childrenCollection?.items?.find((c: any) => c.sys.id === item.contentType.sys.id)
  );

  item.cardImage =
    (item.cardImage && {
      title: item.cardImage.title ?? "",
      description: "",
      contentType: item.cardImage.contentType ?? item.cardImage.file.contentType ?? "",
      fileName: item.cardImage.fileName ?? item.cardImage.file.fileName ?? "",
      url: item.cardImage.url ?? item.cardImage.file.url ?? "",
      width: item.cardImage.width ?? item.cardImage.file.details.image.width ?? "",
      height: item.cardImage.height ?? item.cardImage.file.details.image.height ?? "",
    }) ??
    null;

  const sectors =
    item.sectors || (item.sectorsCollection && item.sectorsCollection.items && item.sectorsCollection.items.length)
      ? [...useTree(CollectionType.sectors, item.sectors ?? item.sectorsCollection ?? null)]
      : null;
  const researchAreas =
    item.researchAreas ||
    (item.researchAreasCollection && item.researchAreasCollection.items && item.researchAreasCollection.items.length)
      ? [...useTree(CollectionType.researchAreas, item.researchAreas ?? item.researchAreasCollection ?? null)]
      : null;

  return (
    <div
      ref={ref}
      id={`qv-${quickViewId}`}
      className={`col-span-3 w-full bg-white rounded-3xl flex relative ${visible ? "" : "hidden"}`}
    >
      <div
        className="absolute top-0 right-0 m-5 p-5 cursor-pointer hover:opacity-70"
        onClick={() => {
          window.scrollBy({
            top: -1 * ref.current?.offsetHeight,
            behavior: "smooth",
          });

          setQuickViewId(null);
        }}
      >
        <SVG src={`/images/icons/close.svg`} width="14" height="14" cacheRequests={true} />
      </div>
      <div className="flex-none bg-slate-200 w-2/5 h-full rounded-l-3xl">
        {item.cardImage && (
          <Image
            image={item.cardImage}
            alt={item.cardImage.title ?? ""}
            type="fluid"
            loading="lazy"
            className="flex h-full rounded-l-3xl"
            objectFit="cover"
          />
        )}
      </div>
      <div className="px-12 py-20 pb-0 text-slate-400">
        <Heading3>{item.title}</Heading3>
        <Paragraph>{item.metaDescription}</Paragraph>
        <Button {...item} internalPage={item} variant="Secondary" label={`View ${contentType.singularName}`} />
        <div className="mt-10 pb-10">
          {sectors &&
            sectors.length > 0 &&
            sectors.slice(0, 6).map((sector: Contentful_Sector, key: number) => {
              if (!sector) return null;
              return <CollectionItem item={sector} key={key} />;
            })}
          {researchAreas &&
            researchAreas.length > 0 &&
            researchAreas.slice(0, 6).map((researchArea: Contentful_ResearchArea, key: number) => {
              if (!researchArea) return null;
              return <CollectionItem item={researchArea} key={key} />;
            })}
        </div>
      </div>
    </div>
  );
}

export default React.memo(QuickViewContents);
