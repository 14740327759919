import React from "react";
import classnames from "classnames";

function Heading4({ className, children }: { className: string; children: React.ReactNode }) {
  return <h4 className={classnames(["text-2xl", "leading-tight", className])}>{children}</h4>;
}

Heading4.defaultProps = {
  className: "",
};

// export default Heading4;
export default React.memo(Heading4);
