import React from "react";
import get from "lodash/get";
import { graphql, useStaticQuery } from "gatsby";
import { Contentful_ImageWithFocalPoint } from "graphql-types";
import Image from "../image";

function ImageWithFocalPoint({ id }: { id: string }) {
  const data = useStaticQuery(imageWithFocalPointQuery);
  const items: Contentful_ImageWithFocalPoint[] = get(data, "contentful.imageWithFocalPointCollection.items");
  const asset = items.find((item: Contentful_ImageWithFocalPoint) => item.sys.id === id);
  if (!asset || !asset.image) return null;

  return (
    <div className="py-3 md:py-6 last:p-0 text-center">
      <Image type="fluid" image={asset.image} alt={asset.image.title ?? ""} title={asset.image.title ?? ""} />
      {asset.image.description && <div className="px-4 text-sm pt-2">{asset.image.description}</div>}
    </div>
  );
}

// export default Image;
export default React.memo(ImageWithFocalPoint);

const imageWithFocalPointQuery = graphql`
  query ImageWithFocalPointQuery {
    contentful {
      imageWithFocalPointCollection(limit: 1000) {
        items {
          ...imageWithFocalPointFields
        }
      }
    }
  }
`;
