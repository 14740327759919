import React from "react";
import SVG from "react-inlinesvg";
import filesize from "filesize";
import mime from "mime-types";
import Link from "../components/link";
import { Contentful_Link } from "graphql-types";

interface Props extends Contentful_Link {
  forceCurrentColor: boolean;
  forceLargeButton: boolean;
}

function Button(props: Props) {
  const ButtonSampleType: any = {
    Primary: `font-semibold overflow-hidden text-base leading-tight rounded-full hover:opacity-75 ${
      props.forceCurrentColor ? "bg-current" : "text-white bg-slate-500 border-slate-500"
    }`,
    Secondary: `font-semibold overflow-hidden text-base leading-tight border-2 rounded-full hover:opacity-75 ${
      props.forceCurrentColor ? "text-current" : "text-slate-500 bg-transparent border-slate-500"
    }`,
    Text:
      "text-current underline transition-opacity duration-200 opacity-100 hover:opacity-75 text-xl text-slate-400 font-medium",
    "Basic Text": "text-current underline transition-opacity duration-200 opacity-100 hover:opacity-75",
  };

  const ButtonSampleSize: any = {
    noSpacing: "",
    default: "py-3 px-6",
    medium: "py-4 px-6",
    large: "py-5 px-8",
  };

  const ButtonSampleIcon: any = {
    None: "none",
    Download: "download",
    ExternalLink: "external",
  };

  const humanFileSize = props.file && filesize(props.file.size ?? 0, { round: 0 });
  const currentType = ButtonSampleType[props.variant || "Primary"];
  const currentSizeName = props.forceLargeButton ? "large" : props.buttonSize || "default";
  const currentSize = ButtonSampleSize[currentSizeName];

  let currentIcon = props.externalUrl
    ? ButtonSampleIcon.ExternalLink
    : props.file
    ? ButtonSampleIcon.Download
    : ButtonSampleIcon.None;

  let currentButtonSize =
    currentType == ButtonSampleType.Text || currentType == ButtonSampleType["Basic Text"]
      ? ButtonSampleSize.noSpacing
      : currentSize;

  return (
    <Link {...props} className={`relative opacity-100 inline-block ${currentButtonSize} ${currentType}`}>
      {currentType != ButtonSampleType.Text && currentType != ButtonSampleType["Basic Text"] && (
        <span className="absolute top-0 left-0 right-0 bottom-0 bg-white transition-opacity duration-200 opacity-0 hover:opacity-25" />
      )}

      <span className="relative pointer-events-none">
        <span className="flex flex-no-wrap items-center justify-center">
          {currentType != ButtonSampleType.Text &&
            currentType != ButtonSampleType["Basic Text"] &&
            currentIcon != ButtonSampleIcon.None && (
              <span className={`${props.label ? "mr-3" : ""}`}>
                <SVG src={`/images/icons/${currentIcon}.svg`} width="14" height="17" title="" cacheRequests={true} />
              </span>
            )}
          {(currentType == ButtonSampleType.Text || currentType == ButtonSampleType["Basic Text"]) &&
            currentIcon != ButtonSampleIcon.None && (
              <span className={`${props.label ? "mt-0.5 mr-1.5" : ""}`}>
                <SVG src={`/images/icons/${currentIcon}.svg`} className="h-2" cacheRequests={true} />
              </span>
            )}
          {props.label && (
            <span className={`${props.forceCurrentColor && props.variant == "Primary" ? "text-white" : ""}`}>
              {props.label}
              {currentIcon == ButtonSampleIcon.Download && <> · {humanFileSize}</>}
            </span>
          )}
        </span>
      </span>
    </Link>
  );
}

Button.defaultProps = {
  forceCurrentColor: false,
  forceLargeButton: false,
};

// export default Button;
export default React.memo(Button);
