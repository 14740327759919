import React, { useEffect, useState } from "react";
import { TileSize } from "../../tile/types";
import Tile from "../../tile/entry";
import { getSizeClassName } from "../../tile/index";

function QuickViewTileWrapper({ toggleQuickView, ...props }: { count: any; sys: any; toggleQuickView: any }) {
  const [quickViewButton, setQuickViewButton] = useState(false);
  const sizeClassName = getSizeClassName(TileSize.Tile);
  return (
    <div className="relative" onMouseEnter={() => setQuickViewButton(true)} onMouseLeave={() => setQuickViewButton(false)}>
      {quickViewButton && (
        <div className={`absolute w-full ${sizeClassName}`} onClick={toggleQuickView}>
          <div
            className="hidden lg:block absolute z-30 font-semibold text-slate-400 text-base cursor-pointer hover:bg-gray-100 leading-tight py-3 px-3 xl:py-4 xl:px-6 bg-white rounded-full"
            style={{ left: "50%", transform: "translateX(-50%)", bottom: "20px" }}
          >
            Quick View
          </div>
        </div>
      )}
      <Tile entry={props} size={TileSize.Tile} count={props.count} />
    </div>
  );
}

export default React.memo(QuickViewTileWrapper);
