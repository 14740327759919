import React from "react";
import classnames from "classnames";

function Heading6({ className, children }: { className: string; children: React.ReactNode }) {
  return (
    <h5 className={classnames(["text-lg", "leading-tight", "font-medium", "mb-4", "lg:mb-1", "last:mb-0", className])}>
      {children}
    </h5>
  );
}

Heading6.defaultProps = {
  className: "",
};

// export default Heading5;
export default React.memo(Heading6);
