import React, { useContext } from "react";
import {
  Contentful_SiteSettings,
  Contentful_Article,
  Contentful_ResearchArea,
  Contentful_Sector,
  Contentful_StaffMember,
  Maybe,
} from "graphql-types";
import { useAsync } from "react-async-hook";
const { richTextFromMarkdown } = require("@contentful/rich-text-from-markdown");
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import dayjs from "dayjs";
import { Link } from "gatsby-plugin-intl";
import classnames from "classnames";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import { Type, ContentTypeColour } from "../contexts/ThemeContext";
import useTree, { CollectionType } from "../hooks/useTree";
import RichText from "../components/rich-text";
import Heading1 from "../components/rich-text-elements/heading-1";
import Heading4 from "../components/rich-text-elements/heading-4";
import Badge from "../components/badge";
import Image from "../components/image";
import InlineSVG from "../components/inline-svg";
import Heading5 from "../components/rich-text-elements/heading-5";
import Podcast from "../components/blocks/podcast";
import RelatedArticles from "../components/related-articles";
import GetInTouch from "../components/blocks/get-in-touch";
import { textRenderOptions, textToSuperscript } from "../utils/text-render";

export const CollectionItem = React.memo(function CollectionItem({
  item,
}: {
  item: Contentful_Sector | Contentful_ResearchArea | any;
}) {
  const className = "inline-block text-xs font-semibold text-slate-400 border border-current rounded px-3 py-1 mb-3 mr-3";
  // if (!item.landingPage || !item.landingPage.urlSlug) return null;
  return (
    <>
      {item.landingPage && item.landingPage.urlSlug && (
        <Link to={`/${item.landingPage.urlSlug}`} title={item.label ?? ""} className={className}>
          {item?.label}
        </Link>
      )}
      {!item.landingPage && <span className={`${className} opacity-50`}>{item?.label}</span>}
      {item?.childrenCollection?.items.map((i: any, k: number) => {
        if (!i) return null;
        // if (!i.landingPage || !i.landingPage.urlSlug) return null;
        return (
          <React.Fragment key={k}>
            {i.landingPage && i.landingPage.urlSlug && (
              <Link to={`/${i.landingPage.urlSlug}`} title={i.label ?? ""}>
                <span className={className}>{i?.label}</span>
              </Link>
            )}
            {!i.landingPage && <span className={`${className} opacity-50`}>{i?.label}</span>}
          </React.Fragment>
        );
      })}
    </>
  );
});

const Hero = React.memo(function Hero(article: Contentful_Article) {
  const isPodcast = Type(article.contentType?.name ?? "") === ContentTypeColour.Podcasts;

  const asyncHeroBlurb = useAsync(fetchHeroBlurb, [article.heroBlurb]);
  const color = Type(article.contentType?.name ?? "");
  const headingTextColor = color === "slate" ? "text-slate-400" : isPodcast ? "text-white" : `text-${color}-500`;
  const blurbTextColor = color === "slate" ? "text-slate-400" : `text-${color}-500`;

  return (
    <div
      className={classnames("text-center", {
        "bg-purple-400 pt-32 pb-20 mb-12 text-white": isPodcast,
        "container mx-auto px-6 pt-32": !isPodcast,
      })}
    >
      {article.contentType && (
        <div className="mb-10 pointer-events-none">
          <Badge text={article.contentType?.singularName} />
        </div>
      )}
      <Heading1 className={`mb-10 md:px-20 ${headingTextColor}`}>{textToSuperscript(article.title)}</Heading1>
      <p
        className={classnames("text-base font-medium mb-10", {
          "text-slate-400": !isPodcast,
        })}
      >
        {article.byline && <span>{article.byline}</span>}
        {article.byline && !article.eventDate && <span> &middot; </span>}
        {!article.eventDate && <span>{dayjs(article.publishDate).format("DD MMM YYYY")}</span>}
      </p>
      {isPodcast && (
        <div className="container mx-auto px-6 lg:px-20">
          <Podcast {...article} />
        </div>
      )}
      {!isPodcast && article.heroBlurb && asyncHeroBlurb && asyncHeroBlurb.result && (
        <div className={`md:w-3/4 xl:max-w-screen-md mb-10 mx-auto text-left ${blurbTextColor}`}>
          <Heading4 className="font-medium">{documentToReactComponents(asyncHeroBlurb.result, textRenderOptions)}</Heading4>
        </div>
      )}
    </div>
  );
});

const fetchHeroBlurb = async (content: any) => await richTextFromMarkdown(content);

function Article({ article }: { article: Contentful_Article }) {
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const sectors =
    article.sectorsCollection && article.sectorsCollection.items && article.sectorsCollection.items.length
      ? [...useTree(CollectionType.sectors, article.sectorsCollection ?? null)]
      : null;
  const researchAreas =
    article.researchAreasCollection && article.researchAreasCollection.items && article.researchAreasCollection.items.length
      ? [...useTree(CollectionType.researchAreas, article.researchAreasCollection ?? null)]
      : null;
  const credits: Array<Maybe<Contentful_StaffMember>> | undefined = article.creditsCollection?.items.filter(
    (x: any) => x !== null
  );

  const isPodcast = Type(article.contentType?.name ?? "") === ContentTypeColour.Podcasts;
  const asyncHeroBlurb = useAsync(fetchHeroBlurb, [article.heroBlurb]);

  const color = Type(article.contentType?.name ?? "");
  const blurbTextColor = color === "slate" ? "text-slate-400" : `text-${color}-500`;

  const spotifyPodcastUrl = article.podcast?.channel?.spotifyPodcastUrl ?? "";
  const applePodcastUrl = article.podcast?.channel?.applePodcastUrl ?? "";

  return (
    <>
      <article className="mx-auto">
        <Hero {...article} />

        <div className="container mx-auto px-6 mb-12">
          {isPodcast && article.heroBlurb && asyncHeroBlurb && asyncHeroBlurb.result && (
            <div className={`md:w-2/3 xl:max-w-screen-md mb-10 mx-auto text-left ${blurbTextColor}`}>
              <Heading4 className="font-medium">{documentToReactComponents(asyncHeroBlurb.result)}</Heading4>
            </div>
          )}

          {isPodcast && !article.heroBlurb && article.podcast && article.podcast.description && (
            <div className={`md:w-2/3 xl:max-w-screen-md mb-10 mx-auto text-left ${blurbTextColor}`}>
              <Heading4 className="font-medium">{article.podcast.description}</Heading4>
            </div>
          )}

          {article.cardImage && (
            <div className="mb-8">
              <Image image={article.cardImage} type={"fluid"} maxWidth={2200} maxHeight={1200} alt={article.title ?? ""} />
            </div>
          )}
        </div>

        {article.heroBlurb && asyncHeroBlurb && asyncHeroBlurb.result && article.content && (
          <div className="md:w-3/4 lg:w-1/2 mx-auto text-lg text-slate-400 article-wrapper px-10 md:px-6">
            <RichText {...article.content} wrapClassName="" highlightColorClassName={blurbTextColor} />
          </div>
        )}

        {!article.heroBlurb && article.content && (
          <div className="md:w-3/4 lg:w-1/2 mx-auto text-lg text-slate-400 article-wrapper px-10 md:px-6">
            <RichText {...article.content} wrapClassName="" highlightColorClassName={blurbTextColor} />
          </div>
        )}

        <div className="container mx-auto px-6 mb-12">
          {isPodcast && (spotifyPodcastUrl || applePodcastUrl) && (
            <div className="text-center text-slate-400 my-3 lg:my-6">
              <div className="mx-auto border-l-2 w-auto inline-block border-slate-300" style={{ height: "80px" }} />
              <Heading4 className="font-medium mt-6">Listen and subscribe to our podcast</Heading4>
              <div className="my-3 lg:my-6">
                {applePodcastUrl && (
                  <div className="mb-3 lg:mb-6 last:mb-0">
                    <a href={applePodcastUrl} className="font-medium text-xl underline transition-opacity hover:opacity-80">
                      Via Apple Podcasts
                    </a>
                  </div>
                )}

                {spotifyPodcastUrl && (
                  <div className="mb-3 lg:mb-6 last:mb-0">
                    <a
                      href={spotifyPodcastUrl}
                      className="font-medium text-xl underline transition-opacity hover:opacity-80"
                    >
                      Via Spotify
                    </a>
                  </div>
                )}
              </div>

              <div className="mx-auto border-l-2 w-auto inline-block border-slate-300" style={{ height: "80px" }} />
            </div>
          )}

          {((sectors && sectors.length > 0) ||
            (researchAreas && researchAreas.length > 0) ||
            (credits && credits.length > 0) ||
            article.marketingTeamCredit) && (
            <>
              <hr className="my-10" />
              <div className="md:w-3/4 lg:w-1/2 mx-auto" style={{ maxWidth: "552px" }}>
                <div
                  className={classnames({
                    "mb-6": (credits && credits.length > 0) || article.marketingTeamCredit,
                    "-mb-3": !((credits && credits.length > 0) || article.marketingTeamCredit),
                  })}
                >
                  {sectors &&
                    sectors.length > 0 &&
                    sectors.map((sector: Contentful_Sector, key: number) => {
                      if (!sector) return null;
                      return <CollectionItem item={sector} key={key} />;
                    })}
                  {researchAreas &&
                    researchAreas.length > 0 &&
                    researchAreas.map((researchArea: Contentful_ResearchArea, key: number) => {
                      if (!researchArea) return null;
                      return <CollectionItem item={researchArea} key={key} />;
                    })}
                </div>

                {((credits && credits.length > 0) || article.marketingTeamCredit) && (
                  <div className="text-slate-400">
                    {credits && credits.length > 0 && (
                      <>
                        <Heading4 className="mb-5 font-medium">Related people</Heading4>
                        <div className="mb-10">
                          {credits.map((credit: Contentful_StaffMember | null, key: number) => {
                            if (!credit) return null;
                            const fullName = `${credit.firstName} ${credit.lastName}`;
                            return (
                              <Link
                                to={`/staff/${credit.urlSlug}`}
                                title={fullName.trim() ?? ""}
                                className="flex items-center mb-5 last:mb-0"
                                key={key}
                              >
                                {credit.photo && (
                                  <div className="mr-8">
                                    <Image
                                      image={credit.photo}
                                      alt={fullName.trim() ?? ""}
                                      type={"fluid"}
                                      className="w-auto max-w-none rounded-full"
                                      style={{ width: "58px" }}
                                    />
                                  </div>
                                )}
                                <div>
                                  <Heading5 className="mb-2">
                                    {credit.title && <span>{credit.title}. </span>}
                                    {fullName}
                                  </Heading5>
                                  <p className="text-sm">{credit.bio && credit.bio.split(" ").splice(0, 30).join(" ")}...</p>
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </>
                    )}
                    {article.marketingTeamCredit && SiteSettings.marketingCreditMessage?.json && (
                      <>
                        <Heading4 className="mb-5 font-medium">Media contact</Heading4>
                        <div className="flex items-center">
                          {SiteSettings.minimalLogo && (
                            <div className={`transition-colors duration-200 hover:text-slate-500 mr-8`}>
                              <InlineSVG
                                image={SiteSettings.minimalLogo}
                                className="w-auto max-w-none"
                                style={{ width: "58px" }}
                              />
                            </div>
                          )}
                          {SiteSettings.marketingCreditMessage && SiteSettings.marketingCreditMessage.json && (
                            <div className="text-sm">
                              <RichText {...SiteSettings.marketingCreditMessage} />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="text-slate-400">
            <RelatedArticles article={article} />
          </div>
        </div>
      </article>

      {article.contactBlock && <GetInTouch {...article.contactBlock} />}
    </>
  );
}

export default Article;
