import React, { useState } from "react";
import * as Yup from "yup";
import fetch from "isomorphic-unfetch";
import map from "lodash/map";
import { Formik, Form as FormikForm, Field } from "formik";
import { useIntl } from "gatsby-plugin-intl";
import TextInput from "../../components/fields/input";
import TextAreaInput from "../../components/fields/textarea";
import RadioInput from "../../components/fields/radio";
import { getFormData, ErrorDisplay, Success } from "./utils";
import { CONTACT_DIRECTORY } from "./directory.js";
import Spinner from "../../../static/images/icons/spinner.svg";

interface FormValues {
  "form-name": string;
  name: string;
  email: string;
  role: string;
  organisation: string;
  areaOfInterest: string;
  otherAreaOfInterest: string;
  tellUsMore: string;
  botField: string;
}

function Form(props: any) {
  const intl = useIntl();
  const [toggled, setToggled] = useState<boolean>(false);

  function AreasOfInterest() {
    let c = 0;
    return (
      <>
        {map(CONTACT_DIRECTORY.NewBusiness.areaOfInterest, function (value, key) {
          c++;
          const additionalProps =
            key === "Other" ? { onClick: () => setToggled(true) } : { onClick: () => setToggled(false) };
          return <RadioInput label={key} key={c} name="areaOfInterest" formProps={props} {...additionalProps} />;
        })}
      </>
    );
  }

  return (
    <div className="container mx-auto px-6 my-10">
      {props.errors.general && <ErrorDisplay>{props.errors.general}</ErrorDisplay>}

      {/* Used for Netlify forms processing */}
      <form
        action={`/${intl.locale}/form-submission`}
        method="POST"
        name="NewBusiness"
        data-netlify="true"
        netlify-honeypot="botField"
      >
        <input type="hidden" name="botField" />
        <input type="hidden" name="name" />
        <input type="hidden" name="email" />
        <input type="hidden" name="role" />
        <input type="hidden" name="organisation" />
        <input type="hidden" name="areaOfInterest" />
        <input type="hidden" name="otherAreaOfInterest" />
        <input type="hidden" name="tellUsMore" />
      </form>

      <FormikForm noValidate={true}>
        <Field type="hidden" name="form-name" value={props.values["form-name"]} />
        <div className="space-y-8 max-w-sm">
          <div className="hidden">
            <TextInput label="" name="botField" formProps={props} />
          </div>
          <TextInput label="Name" name="name" autoComplete="name" formProps={props} />
          <TextInput label="Email" name="email" type="email" autoComplete="email" formProps={props} />
          <TextInput label="Role" name="role" autoComplete="organization-title" formProps={props} />
          <TextInput label="Organisation" name="organisation" autoComplete="organization" formProps={props} />
          <div className="text-lg">
            <label className="block mb-4" htmlFor="areaOfInterest">
              <span className="text-xl font-medium">Area of Interest</span>
            </label>
            <div className="space-y-2">
              <AreasOfInterest />
              {props.errors.areaOfInterest && props.touched.areaOfInterest ? (
                <ErrorDisplay>{props.errors.areaOfInterest}</ErrorDisplay>
              ) : null}
            </div>
          </div>

          <div className={`pt-4 ${toggled ? "block" : "hidden"}`}>
            <TextAreaInput label="Short description" name="otherAreaOfInterest" formProps={props} />
          </div>

          <div className="pt-4">
            <TextAreaInput label="Tell us more" name="tellUsMore" formProps={props} />
          </div>

          <button
            type="submit"
            disabled={props.isSubmitting}
            className="disabled:opacity-50 relative leading-tight overflow-hidden text-lg opacity-100 w-full block py-4 px-6 font-semibold rounded-full text-white border-green-500 bg-green-500"
          >
            {!props.isSubmitting && <div>Submit Enquiry</div>}
            {props.isSubmitting && (
              <div className="flex items-center justify-center">
                <div className="mr-4">Submitting</div>
                <Spinner className="animate-spin h-4" />
              </div>
            )}
          </button>
        </div>
      </FormikForm>
    </div>
  );
}

export default function () {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);

  const initialValues: FormValues = {
    "form-name": "NewBusiness",
    name: "",
    email: "",
    role: "",
    organisation: "",
    areaOfInterest: "",
    otherAreaOfInterest: "",
    tellUsMore: "",
    botField: "",
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: "formValidation.nameRequired" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "formValidation.emailInvalid" }))
      .required(intl.formatMessage({ id: "formValidation.emailRequired" })),
    role: Yup.string().required(intl.formatMessage({ id: "formValidation.roleRequired" })),
    organisation: Yup.string().required(intl.formatMessage({ id: "formValidation.organisationRequired" })),
    areaOfInterest: Yup.string().required(intl.formatMessage({ id: "formValidation.areaOfInterestRequired" })),
    otherAreaOfInterest: Yup.string().when("areaOfInterest", {
      is: "Other",
      then: Yup.string().required(intl.formatMessage({ id: "formValidation.descriptionRequired" })),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(
        values: any,
        actions: {
          setFieldError: (arg0: string, arg1: string) => void;
          setSubmitting: (arg0: boolean) => void;
        }
      ) => {
        fetch(`/${intl.locale}/form-submission`, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: getFormData(values),
        })
          .then((r) => {
            if (r.ok) {
              return r;
            } else {
              actions.setFieldError("general", intl.formatMessage({ id: "formValidation.unexpectedError" }));

              setTimeout(() => {
                actions.setFieldError("general", "");
              }, 5000);
              return Promise.reject(r.statusText);
            }
          })
          .then(() => {
            setSubmitted(true);
            const isSSR = typeof window === "undefined";
            if (!isSSR) {
              window.scrollTo({
                top:
                  document && document.getElementById("form-success-message")
                    ? document.getElementById("form-success-message").offsetTop - 90
                    : 120,
                behavior: "smooth",
              });
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(controls: any) => (
        <>
          {!submitted && <Form {...controls} />}
          {submitted && <Success />}
        </>
      )}
    </Formik>
  );
}
