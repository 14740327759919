import React from "react";
import Heading4 from "../rich-text-elements/heading-4";

export function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return new URLSearchParams(formData).toString();
}

export function ErrorDisplay({ children }: { children: any }) {
  return <div className="text-sm pt-1 text-red-400">{children}</div>;
}

export function Success() {
  return (
    <div id="form-success-message" className="container mx-auto px-6 my-10">
      <Heading4>
        <span className="flex items-center md:space-x-2 mb-2">
          <svg
            className="w-6 h-6 hidden md:block"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>Thank you – we have received for your enquiry.</span>
        </span>
      </Heading4>
      <p className="opacity-80">We will endeavour to be in touch as soon as possible.</p>
    </div>
  );
}
