import React from "react";
import { Contentful_BlockGetInTouch } from "graphql-types";
import useColourway from "../../hooks/useColourway";
import SVG from "react-inlinesvg";
import Image from "../image";
import Button from "../button";

function GetInTouch(props: Contentful_BlockGetInTouch) {
  if (!props?.sys) return null;

  const { color } = useColourway(props.colourway || null);

  return (
    <section className={`flex relative overflow-hidden py-24 bg-${color}-400`}>
      <div className="absolute inset-0">
        <div className="bg-black pointer-events-none w-full h-full">
          {props.image && props.image.image && (
            <Image
              image={props.image.image}
              alt={props.image.image.title ?? ""}
              maxWidth={1200}
              type={"fluid"}
              loading="lazy"
              className="flex h-full bg-black blend-screen"
              objectFit="cover"
              focalPoint={props.image.focalPoint}
            />
          )}
        </div>
      </div>

      <div className="relative container mx-auto px-6 z-20 pointer-events-none">
        <div className={`relative flex w-full justify-center text-${color}-500`}>
          <div className="relative w-full text-center">
            <div className="relative inline-block text-center">
              <div className="absolute top-1/2 transform -translate-y-1/2 w-full">
                <SVG src="/images/icons/circle.svg" title="" cacheRequests={true} className="w-full" />
              </div>
              <div className="relative pointer-events-auto">
                <div className="px-4 py-56">
                  {props.heading && <h2 className="font-medium text-3xl md:text-5xl leading-none">{props.subHeading}</h2>}
                  {props.subHeading && (
                    <p className={`font-medium mt-1 leading-none text-5xl md:text-7xl`}>{props.heading}</p>
                  )}
                  {props.callToAction && (
                    <div className="mt-3 lg:mt-6">
                      <Button {...props.callToAction} forceCurrentColor={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// export default GetInTouch;
export default React.memo(GetInTouch);
