// This is the mapping of contact form enquiries based on form data.
// The @plantandfood.co.nz domain is not entered here to enforce emails
// only to be sent on this domain and makes it easier for debug mode.
export const CONTACT_DIRECTORY = {
  SpeakingOpportunities: {
    _ALL: "website",
  },
  NewBusiness: {
    _ALL: null,
    areaOfInterest: {
      "IP licensing opportunities": "peter.cook",
      "International Development programmes": "suzie.newman",
      "R&D Services": "blake.mackie",
      "Co-innovation opportunities": "peter.cook",
      Other: "website",
    },
  },
  ResearchCollaboration: {
    _ALL: "richard.newcomb",
    researchAreasOfInterest: {
      "New plant cultivars": "cultivar.innovation",
      "Genetics and genomics": "cultivar.innovation",
      "Biosecurity and crop protection": "bioprotection",
      "Conservation and ecology": "bioprotection",
      "Crop optimisation": "sustainable.production",
      "Soil, water and climate": "sustainable.production",
      "Supply chain optimisation": ["sustainable.production", "bioprotection"],
      "Sensing technologies": ["sustainable.production", "food.innovation"],
      "Food safety": "food.innovation",
      "Consumer research": "food.innovation",
      "Health and nutrition": "food.innovation",
      "Food and ingredient formulation": "food.innovation",
      "Total utilisation": ["food.innovation", "seafood.technologies"],
      "Fish breeding": "seafood.technologies",
      "Seafood production": "seafood.technologies",
      "Blue Sky Science": null,
      Other: null,
    },
    country: {
      "New Zealand": null,
      Australia: "andrew.granger",
      "U.S.": "peter.cook",
      China: "liverpool.zhang",
      "Rest of the  world": null,
    },
  },
};
