import React from "react";
import { Contentful_BlockPartners, Contentful_Partners } from "graphql-types";

import Image from "../../components/image";
import Link from "../../components/link";

/**
 *
 * @param param0
 * @returns
 */
const PartnerBlock = ({ partner, key }: { partner: Contentful_Partners; key: number }) => {
  if (partner?.externalUrl || partner?.internalPage) {
    return (
      <Link {...partner}>
        <Image
          alt={partner?.name}
          aspectRatio={1}
          key={key}
          objectFit="cover"
          image={partner?.logo}
          type={"fluid"}
          className={`inset-0 z-0 rounded-3xl`}
        />
      </Link>
    );
  }

  return (
    <Image
      alt={partner?.name}
      aspectRatio={1}
      key={key}
      objectFit="cover"
      image={partner?.logo}
      type={"fluid"}
      className={`inset-0 z-0 rounded-3xl`}
    />
  );
};

function Partners(props: Contentful_BlockPartners) {
  if (!props.sys) return null;

  const { title, random = false, partnersCollection } = props;
  const partners =
    (random
      ? partnersCollection?.items.filter((x) => x).sort(() => 0.5 - Math.random())
      : partnersCollection?.items.filter((x) => x)) ?? [];
  return (
    <section>
      <div className="pt-8 pb-8 container mx-auto px-6">
        <h3 className="text-4xl font-medium leading-tight lg:text-5xl mb-4 last:mb-0">{title}</h3>
        <div className="pt-4 grid gap-4 grid-cols-2 md:grid md:grid-cols-3 md:gap-6">
          {partners
            .filter((x) => x)
            .sort(() => (random ? Math.random() - 0.5 : 1))
            .map((partner, index) => (
              <div key={index}>
                {partner && (
                  <div className="w-full">
                    <PartnerBlock partner={partner} key={index} />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default React.memo(Partners);
