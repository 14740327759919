import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import SVG from "react-inlinesvg";
import { Contentful_BlockVideo } from "graphql-types";
import ReactPlayer from "react-player";
import fetchJSON from "../../utils/fetch-json";
import classnames from "classnames";
import { PropsWithLocation } from "../../utils/types";

export enum PlayerType {
  Vimeo,
  Youtube,
}

interface PlayerProps {
  id?: string;
  video?: string;
  type: PlayerType;
}

interface PlayerPropsState {
  isAvailable: boolean;
  backgroundImage: string;
}

const ResponsivePlayer = ({ video, id, type }: PlayerProps) => {
  const buttonRef = useRef(null);
  const iconRef = useRef(null);
  const loaderRef = useRef(null);
  const playerContainer = useRef<HTMLDivElement>(null);
  const isInitialized = useRef(false);
  const onTransition = useRef(false);
  const [state, setState] = useState<PlayerPropsState>({
    isAvailable: true,
    backgroundImage: "",
  });

  const loaderCounter = useRef(0);

  const videoOptions: any = {
    youtube: {
      width: "100%",
      height: "100%",
      playerVars: {
        controls: 1,
        autoplay: 0,
        rel: 0,
        showinfo: 0,
      },
    },
    vimeo: {
      playerOptions: {
        controls: true,
        byline: false,
        title: false,
      },
    },
  };

  const onClick = () => {
    if (onTransition) {
      play();
      onTransition.current = true;
      gsap.fromTo(iconRef.current, { scale: 1 }, { scale: 0, duration: 0.6, ease: "power3.inOut" });
      gsap.fromTo(loaderRef.current, { opacity: 0 }, { opacity: 1, duration: 0.6, ease: "power3.inOut" });
    }
  };

  const getVimeoThumbnail = () => {
    const currentVideoURL: any = video;

    const fetchURL = `https://vimeo.com/api/oembed.json?url=${encodeURI(currentVideoURL)}`;

    fetchJSON(fetchURL)
      .then((data) => {
        const thumbnail = data.json.thumbnail_url;
        if (thumbnail) {
          const thumbnailLarge = thumbnail.replace("_640.jpg", "_1400.jpg");
          setState((prevState) => ({
            ...prevState,
            backgroundImage: thumbnailLarge,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadYoutubeImage = () => {
    const youtubeImageQuality = ["maxresdefault.jpg", "hqdefault.jpg", "mqdefault.jpg", "sddefault.jpg"];
    const url = `//img.youtube.com/vi/${id}/${youtubeImageQuality[loaderCounter.current]}`;
    const image = new Image();
    image.onload = () => {
      if (image.width < 150 && loaderCounter.current < 1) {
        loaderCounter.current += 1;
        loadYoutubeImage();
      } else {
        setState((prevState) => ({
          ...prevState,
          backgroundImage: `url(${url})`,
        }));
      }
    };
    image.src = url;
  };

  const getYoutubeThumbnail = () => {
    loadYoutubeImage();
  };

  const play = () => {
    if (!isInitialized.current) {
      gsap.fromTo(
        playerContainer.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.6,
          delay: 0.5,
          ease: "power3.out",
          onComplete: () => {},
        }
      );
      gsap.fromTo(
        buttonRef.current,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.6,
          delay: 0.5,
          ease: "power3.out",
          onComplete: () => {
            setState((prevState) => ({
              ...prevState,
              isAvailable: false,
            }));
          },
        }
      );

      isInitialized.current = true;
    }
  };

  useEffect(() => {
    switch (type) {
      case PlayerType.Youtube:
        getYoutubeThumbnail();
        break;
      case PlayerType.Vimeo:
        getVimeoThumbnail();
        break;
    }

    return () => {
      gsap.killTweensOf([iconRef.current, loaderRef.current, buttonRef.current]);
    };
  }, [state.isAvailable]);

  return (
    <>
      <div
        className="relative h-0 overflow-hidden"
        style={{
          maxWidth: "100%",
          paddingBottom: "56.25%",
        }}
      >
        <div
          ref={playerContainer}
          className={classnames({
            "opacity-0": type === PlayerType.Youtube,
          })}
        >
          <ReactPlayer
            playing={!state.isAvailable}
            url={video}
            className="absolute top-0 left-0 w-full h-full"
            width={"100%"}
            height={"100%"}
            config={videoOptions}
            // onReady={() => console.log("ready")}
          />
        </div>
      </div>
      {state.isAvailable && (
        <button
          ref={buttonRef}
          className={classnames(
            "absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center bg-cover bg-center",
            {
              "bg-gray-200": type === PlayerType.Youtube,
            }
          )}
          onClick={onClick}
        >
          {state.backgroundImage && (
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: state.backgroundImage,
              }}
            ></div>
          )}
          <div
            ref={loaderRef}
            className="absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center opacity-0"
          >
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-color-blue-200 h-32 w-32"></div>
          </div>
          <div
            ref={iconRef}
            className="relative bg-blue-400 cursor-pointer rounded-full text-white flex items-center justify-center"
            style={{
              width: 72,
              height: 72,
            }}
          >
            <SVG src="/images/icons/play.svg" width="32" height="40" title="Play" className="ml-2" cacheRequests={true} />
          </div>
        </button>
      )}
    </>
  );
};

const Video = (props: PropsWithLocation<Contentful_BlockVideo>) => {
  if (!props?.video) return null;

  const videoURL: string = props.video;

  const match =
    videoURL.match(
      /^(https:\/\/vimeo.com\/|https:\/\/player.vimeo.com\/|https:\/\/www.youtube.com\/watch\?v=)[a-z0-9-_+/]+$/i
    ) ?? "";
  const id = videoURL.replace(match[1], "").replace("video/", "");
  const type = videoURL.includes("youtube") ? PlayerType.Youtube : PlayerType.Vimeo;

  return (
    <section className="container mx-auto py-6">
      <div className="relative w-full">
        <ResponsivePlayer video={videoURL} id={id} type={type} />
      </div>
      {props.caption && (
        <div className="relative flex justify-end">
          <div className="lg:max-w-video-text">
            <p className="font-normal text-base text-slate-400 py-4">{props.caption}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Video;
