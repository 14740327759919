import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import { Contentful_BlockSectorsCarousel, Contentful_BlockSectorsCarouselItem } from "graphql-types";
import SectorsCarouselNavList from "./sectors-carousel-nav-list";
import SectorsCarouselNavProgress from "./sectors-carousel-nav-progress";
import useColourway from "../../hooks/useColourway";
import SectorsCarouselHero from "./sectors-carousel-hero";
import SectorsCarouselSwiper from "./sectors-carousel-swiper";

const SectorsCarousel = (props: Contentful_BlockSectorsCarousel) => {
  if (!props.itemsCollection?.items.length) return null;

  const initialState = {
    activeIndex: 0,
  };

  const [state, setState] = useState(initialState);

  const getActiveItem = function (): Contentful_BlockSectorsCarouselItem {
    return items[state.activeIndex];
  };

  let items: Contentful_BlockSectorsCarouselItem[] = get(props, "itemsCollection.items");
  const activeItem: Contentful_BlockSectorsCarouselItem = getActiveItem();

  const getActiveColourway = function (): object {
    if (!activeItem) {
      return {
        textColor: "black",
        color: "gray",
      };
    }
    return useColourway(activeItem.colourway || null);
  };

  const getItemColourway = function (item: Contentful_BlockSectorsCarouselItem): any {
    if (!item) {
      return {
        textColor: "black",
        color: "gray",
      };
    }
    return useColourway(item.colourway || null);
  };

  const colourway: any = getActiveColourway();
  const activeColourway: any = getActiveColourway();
  const activeBackgroundColorLight: string = `bg-${colourway.color}-200`;
  const activeBackgroundColorDark: string = `bg-${colourway.color}-500`;
  const activeForegroundColorLight: string = `bg-${colourway.color}-400`;
  const activeForegroundColorDark: string = `bg-${colourway.color}-500`;
  const activeFromBackgroundGradientColor: string = `from-${colourway.color}-transparent`;
  const activeToBackgroundGradientColor: string = `to-${colourway.color}-200`;
  const activeTextColor: string = activeColourway.textColor;

  const hero = useRef(null);

  const navProgressComplete = () => {
    setState((prevState) => ({
      ...prevState,
      activeIndex: (prevState.activeIndex + 1) % items.length,
    }));
  };

  const navListItemClick = (index: number) => {
    setState((prevState) => ({
      ...prevState,
      activeIndex: index,
    }));
  };

  const swiperOnNext = () => {
    let currentIndex = (state.activeIndex + 1) % items.length;
    setState((prevState) => ({
      ...prevState,
      activeIndex: currentIndex,
    }));
  };

  const swiperOnPrev = () => {
    let currentIndex = state.activeIndex - 1;
    currentIndex = currentIndex < 0 ? items.length - 1 : currentIndex;
    setState((prevState) => ({
      ...prevState,
      activeIndex: currentIndex,
    }));
  };

  return (
    <>
      <div>
        {items.length > 0 && (
          <div className={`py-16 ${activeBackgroundColorLight}`}>
            <div>
              <SectorsCarouselSwiper minThresholdToSwiper={30} onNext={swiperOnNext} onPrev={swiperOnPrev}>
                <div ref={hero}>
                  {items.length > 0 &&
                    items.map((item: Contentful_BlockSectorsCarouselItem, key: number) => {
                      return (
                        <SectorsCarouselHero
                          key={key}
                          item={item}
                          isAvailable={state.activeIndex === key}
                          foregroundColor={`bg-${getItemColourway(item).color}-500`}
                        />
                      );
                    })}
                </div>
                <div className="my-6 md:my-10">
                  <SectorsCarouselNavList
                    activeIndex={state.activeIndex}
                    items={items}
                    fromBackgroundGradientColor={activeFromBackgroundGradientColor}
                    toBackgroundGradientColor={activeToBackgroundGradientColor}
                    textColor={activeTextColor}
                    onClickItem={navListItemClick}
                  />
                </div>
              </SectorsCarouselSwiper>
            </div>
            {items.length > 1 && (
              <div className="my-6">
                <SectorsCarouselNavProgress
                  activeIndex={state.activeIndex}
                  duration={5}
                  color={activeForegroundColorDark}
                  onComplete={navProgressComplete}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SectorsCarousel;
