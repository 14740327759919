import { graphql, useStaticQuery } from "gatsby";
import { ContentfulArticleConnection } from "graphql-types";

/**
 * Unfortunately gatsby doesn't support dynamic queries or even string interpolations for graphql queries.
 * Is also not possible to pass variables to
 */

const articleQuery = graphql`
  query {
    allContentfulArticle(filter: { node_locale: { eq: "en-NZ" } }) {
      nodes {
        contentful_id
        __typename
        title
        metaDescription
        urlSlug
        heroBlurb {
          heroBlurb
        }
        tags {
          contentful_id
        }
        contentType {
          contentful_id
          name
          singularName
          contentfulchildren {
            contentful_id
          }
        }
        researchAreas {
          contentful_id
        }
        sectors {
          contentful_id
        }
        eventDate
        publishDate
        cardImage {
          title
          contentful_id
          file {
            fileName
            contentType
            url
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
        credits {
          __typename
          contentful_id
        }
      }
    }
  }
`;

/**
 * Fetches articles data from contentful source plugin and normalizes it to graphql data structure.
 * We couldn't fetch all articles using graphql api as the API is very limited in the number of items/size of response it can delivery,
 * @returns
 */
export const useArticleQuery = () => {
  const data = useStaticQuery<{ allContentfulArticle: ContentfulArticleConnection }>(articleQuery);

  return data.allContentfulArticle.nodes.map((x) => {
    return {
      cardImage: x.cardImage && {
        contentType: x.cardImage.file?.contentType,
        fileName: x.cardImage.file?.fileName,
        height: x.cardImage.file?.details?.image?.height,
        width: x.cardImage.file?.details?.image?.width,
        url: x.cardImage.file?.url?.replace("//", "https://"),
        sys: {
          id: x.cardImage.contentful_id,
        },
      },
      eventDate: x.eventDate,
      publishDate: x.publishDate,
      contentType: {
        name: x.contentType?.name,
        singularName: x.contentType?.singularName,
        children: x.contentType?.contentfulchildren?.map((c: any) => ({
          sys: {
            id: c.contentful_id,
          },
        })),
        sys: {
          id: x.contentType?.contentful_id,
        },
      },
      metaDescription: x.metaDescription,
      urlSlug: x.urlSlug,
      title: x.title,
      __typename: "Contentful_Article",
      sys: {
        id: x.contentful_id,
      },
      tags: x.tags,
      researchAreas: x.researchAreas,
      sectors: x.sectors,
      credits: x.credits,
    };
  });
};
