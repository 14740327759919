import { default as Aggregator } from "./blocks/aggregator";
import { default as Table } from "./blocks/table";
import { default as CircularImage } from "./blocks/circular-image";
import { default as Featured } from "./blocks/featured";
import { default as Form } from "./blocks/form";
import { default as FullWidthImage } from "./blocks/full-width-image";
import { default as GetInTouch } from "./blocks/get-in-touch";
import { default as Hero } from "./blocks/hero";
import { default as ImageColourBlock } from "./blocks/image-colour-block";
import { default as ImageText } from "./blocks/image-text";
import { default as InPageNavigation } from "./blocks/in-page-navigation";
import { default as LatestNews } from "./blocks/latest-news";
import { default as PeopleSearch } from "./blocks/people-search";
import { default as People } from "./blocks/people";
import { default as Podcast } from "./blocks/podcast";
import { default as RichText } from "./blocks/rich-text";
import { default as SectorsCarousel } from "./blocks/sectors-carousel";
import { default as SplashImageText } from "./blocks/splash-image-text";
import { default as ThreeColumn } from "./blocks/three-column";
import { default as Tiles } from "./blocks/tiles";
import { default as TwoCircleImage } from "./blocks/two-circle-image";
import { default as TwoColumn } from "./blocks/two-column";
import { default as Video } from "./blocks/video";
import { default as Infographic } from "./blocks/infographic";
import { default as Partners } from "./blocks/partners";
import { default as RawHtml } from "./blocks/raw-html";

export default {
  Aggregator,
  CircularImage,
  Featured,
  Table,
  Form,
  FullWidthImage,
  GetInTouch,
  Hero,
  ImageColourBlock,
  ImageText,
  InPageNavigation,
  LatestNews,
  PeopleSearch,
  People,
  Podcast,
  RichText,
  SectorsCarousel,
  SplashImageText,
  ThreeColumn,
  Tiles,
  TwoCircleImage,
  TwoColumn,
  Video,
  Partners,
  Infographic,
  RawHtml,
};

export type BlockType =
  | "Aggregator"
  | "CircularImage"
  | "Featured"
  | "Table"
  | "Form"
  | "FullWidthImage"
  | "GetInTouch"
  | "Hero"
  | "ImageColourBlock"
  | "ImageText"
  | "InPageNavigation"
  | "LatestNews"
  | "PeopleSearch"
  | "People"
  | "Podcast"
  | "RichText"
  | "SectorsCarousel"
  | "SplashImageText"
  | "ThreeColumn"
  | "Tiles"
  | "TwoCircleImage"
  | "TwoColumn"
  | "Video"
  | "Partners"
  | "Infographic"
  | "RawHtml";
