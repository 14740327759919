import React, { useContext } from "react";
import { Contentful_BlockImageText } from "graphql-types";
import classnames from "classnames";
import Image from "../image";
import RichText from "../rich-text";
import { SideNavContext } from "../../contexts/SideNavContext";

function ImageText(props: Contentful_BlockImageText) {
  if (!props.sys) return null;

  const IsImageFirst: boolean = props.imageFirst || false;
  const HasSideNav = useContext(SideNavContext);

  const isTransparent = props.transparent;

  return (
    <section className={`${!isTransparent && "bg-slate-100"}  mx-auto`} style={{ maxWidth: "2000px" }}>
      <div
        className={classnames("md:flex mb-10", {
          "md:flex-row-reverse": !IsImageFirst,
        })}
      >
        <div
          className={classnames("md:flex-none md:w-1/2", {
            "md:w-1/3": !IsImageFirst,
          })}
        >
          <div className={`${!isTransparent && "bg-black"} pointer-events-none h-60 lg:h-96 min-h-full`}>
            {props.image && props.image.image && (
              <Image
                image={props.image.image}
                alt={props.image.image.title ?? ""}
                maxWidth={1200}
                type={"fluid"}
                loading="lazy"
                className={`flex h-full ${!isTransparent && "bg-black blend-screen"}`}
                objectFit="cover"
                focalPoint={props.image.focalPoint}
              />
            )}
          </div>
        </div>
        <div
          className={classnames("lg:w-1/2 xl:w-1/3 lg:flex lg:items-center lg:justify-end", {
            "lg:w-2/5": HasSideNav,
          })}
        >
          <div
            className={classnames("container lg:max-w-none mx-auto lg:mx-0 px-6 py-10 md:px-10 lg:py-40 lg:px-24", {
              "lg:pr-0": IsImageFirst,
              "lg:pl-0": !IsImageFirst,
              "lg:max-w-sm xl:max-w-none": HasSideNav,
            })}
          >
            <div className="text-slate-400 font-normal leading-normal">
              {props.content && <RichText {...props.content} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// export default ImageText;
export default React.memo(ImageText);
