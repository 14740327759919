import React, { useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { Contentful_Article, Contentful_BlockCaseStudies, Contentful_ContentTypeCollection } from "graphql-types";
import Button from "../../button";
import Heading2 from "../../rich-text-elements/heading-2";
import QuickViewTileWrapper from "./quick-view-tile-wrapper";
import QuickViewContents from "./quick-view-contents";

import { useArticlesAggregatorFiltered } from "../../../utils/article-aggregator-filters";

type Contentful_BlockAggregator = Contentful_BlockCaseStudies;

const Heading = React.memo(function (props: Contentful_BlockAggregator) {
  if (!props.heading) return null;
  return (
    <>
      <div className="text-slate-400">
        <Heading2>{props.heading}</Heading2>
      </div>
      <div className="hidden md:block">
        <CallToAction {...props} />
      </div>
    </>
  );
});

const CallToAction = React.memo(function (props: Contentful_BlockAggregator) {
  if (!props.callToAction) return null;
  return <Button {...props.callToAction} />;
});

function Aggregator(props: Contentful_BlockAggregator) {
  const contentTypeData = useStaticQuery(quickViewQuery);
  const contentTypes: Contentful_ContentTypeCollection = get(contentTypeData, "contentful.contentTypeCollection");
  const itemsPerRow = 3;
  const [match, setMatch] = useState(false);
  const [quickViewId, setQuickViewId] = useState(null);
  const [quickViewRow, setQuickViewRow] = useState(0);
  const showHeading = props.showHeading === null || props.showHeading === void 0 ? true : props.showHeading;
  const filterRule = !props.filterRule ? "OR" : (props.filterRule as "AND" | "OR");
  const ref = useRef<HTMLDivElement>(null);

  const items = useArticlesAggregatorFiltered({
    limit: !props.limit ? 6 : props.limit === "all" ? 100 : Number(props.limit),
    filterRule: filterRule,
    filters: props.filtersCollection,
    sortBy: props.sortBy ?? undefined,
    featuredArticles: props.featuredCollection,
  });

  if (!items || !items.length) return null;

  return (
    <section ref={ref} className="container mx-auto px-6 py-4 md:py-14">
      {showHeading && (
        <div className="flex flex-no-wrap items-center justify-between md:mb-6">
          <Heading {...props} />
        </div>
      )}
      <section className="pt-4 md:grid md:grid-cols-3 md:gap-6">
        {items.map((item: any, key: number) => {
          const props: Contentful_Article | any = {
            __typename: "Contentful_Article",
            sys: item.sys.id,
            title: item.title,
            urlSlug: item.urlSlug,
            metaDescription: item.metaDescription,
            publishDate: item.publishDate ?? null,
            eventDate: item.eventDate ?? null,
            cardImage:
              (item.cardImage && {
                title: item.cardImage.title ?? "",
                description: "",
                contentType: item.cardImage.contentType ?? item.cardImage.file.contentType ?? "",
                fileName: item.cardImage.fileName ?? item.cardImage.file.fileName ?? "",
                url: item.cardImage.url ?? item.cardImage.file.url ?? "",
                width: item.cardImage.width ?? item.cardImage.file.details.image.width ?? "",
                height: item.cardImage.height ?? item.cardImage.file.details.image.height ?? "",
              }) ??
              null,
            contentType: null,
            // contentType: contentTypes.items.find((contentType: any) => contentType.sys.id === item.contentType.sys.id),
          };

          //display based on which row the item was clicked
          const display = Math.ceil((key + 1) / itemsPerRow) === quickViewRow;
          //render only one per row after every row
          const renderQuickView = (key + 1) % itemsPerRow === 0 || key + 1 === items.length;
          return (
            <React.Fragment key={key}>
              <QuickViewTileWrapper
                {...props}
                count={key}
                toggleQuickView={() => {
                  const row = Math.ceil((key + 1) / itemsPerRow);
                  setQuickViewRow(row);
                  const articleId = quickViewId !== props.sys ? props.sys : null;
                  setQuickViewId(articleId);
                }}
              />
              {renderQuickView && (
                <QuickViewContents
                  visible={display}
                  articles={items}
                  quickViewId={quickViewId}
                  contentTypes={contentTypes}
                  setQuickViewId={setQuickViewId}
                />
              )}
            </React.Fragment>
          );
        })}
      </section>
      <div className="md:hidden text-center pb-6">
        <CallToAction {...props} />
      </div>
    </section>
  );
}

export default React.memo(Aggregator);

const quickViewQuery = graphql`
  query QuickViewQuery {
    contentful {
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          childrenCollection(limit: 2) {
            items {
              sys {
                id
                publishedVersion
              }
            }
          }
          name
          singularName
        }
      }
      entryCollection {
        items {
          ... on Contentful_BlockCaseStudies {
            includeLocationCollection(limit: 3) {
              items {
                code
              }
            }
            excludeLocationCollection(limit: 3) {
              items {
                code
              }
            }
          }
        }
      }
    }
  }
`;
