import React, { useContext, useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useTree, { CollectionType } from "../../hooks/useTree";
import Filter, { GroupType } from "../filter";
import { FilterContext } from "../filter/context";
import { algoliaFilters, getFilters } from "../filter/utils";
import Loading from "../collection/loading";
import Results from "../collection/results";
import Error from "../collection/error";
import { ReorderFilter } from "../../utils/reorder-filter";

const client = algoliasearch(process.env.ALGOLIA_APP_ID ?? "", process.env.ALGOLIA_APP_SEARCH_KEY ?? "");
const index = client.initIndex(process.env.ALGOLIA_STAFF_INDEX ?? "");

function Collection() {
  const filterContext: GroupType[] = useContext(FilterContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [infiniteLoading, setInfiniteLoading] = useState(true);
  const [results, setResults] = useState<any>([]);

  function loadMore() {
    setInfiniteLoading(true);
    search();
  }

  const infiniteRef = useInfiniteScroll({
    loading: infiniteLoading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  function search(reset = false) {
    let filters = algoliaFilters(filterContext);
    setHasError(false);

    if (reset) {
      setLoading(true);
      setInfiniteLoading(false);
      setPageIndex(0);
      setHasNextPage(false);
    }

    index
      .search("", {
        facetFilters: [
          getFilters("departments", filters),
          getFilters("researchAreasCollection", filters),
          getFilters("sectorsCollection", filters),
        ].filter((e) => e.length),
        hitsPerPage: 40,
        page: reset ? 0 : pageIndex,
      })
      .then(({ hits, nbPages, page }: { hits: any; nbPages: number; page: number }) => {
        if (reset) setResults([...hits]);
        else setResults([...results, ...hits]);

        setTimeout(() => {
          setLoading(false);
          setInfiniteLoading(false);
          setPageIndex(page + 1);
          setHasNextPage(nbPages - 1 > page);
        }, 500);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
        setHasNextPage(false);
        setPageIndex(0);
      });
  }

  useEffect(() => {
    search(true);
  }, [, filterContext]);

  return (
    <>
      <Error hasError={hasError} />
      <Loading loading={loading} />
      <div ref={infiniteRef}>
        <Results loading={loading} hasError={hasError} results={results} />
        <Loading loading={infiniteLoading} max={4} />
      </div>
    </>
  );
}

function PeopleSearch(props: any) {
  if (!props.sys) return null;

  const departmentsData = [...useTree(CollectionType.departments, null, true, false)];
  const sectorsData = [...useTree(CollectionType.sectors, null, true, false)];
  const researchAreasData = [...useTree(CollectionType.researchAreas, null, true, false)];
  const groups: GroupType[] = [
    {
      groupLabel: "Department",
      ref: "departments", // used for Algolia reference
      items: departmentsData,
    },
    {
      groupLabel: "Research Area",
      ref: "researchAreasCollection", // used for Algolia reference
      items: researchAreasData,
    },
    {
      groupLabel: "Sector",
      ref: "sectorsCollection", // used for Algolia reference
      items: sectorsData,
    },
  ];

  return (
    <div className="container mx-auto px-6 py-14">
      <Filter groups={ReorderFilter(groups)}>
        <Collection />
      </Filter>
    </div>
  );
}

// export default PeopleSearch;
export default React.memo(PeopleSearch);
