import React from "react";
import Tile from "../tile/entry";
import { Contentful_Article, Contentful_StaffMember } from "graphql-types";
import { TileSize } from "../tile/types";

function Results({
  loading,
  hasError,
  results,
  contentTypes,
}: {
  loading: boolean;
  hasError: boolean;
  results: any;
  contentTypes?: any;
}) {
  if (loading || hasError) return null;

  if (!results.length)
    return <div className="mb-40 text-slate-500 text-lg">Sorry, no results were found for the selected filters.</div>;

  return (
    <div className="md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-6">
      {results.map((result: any, key: number) => {
        const __typename = result.sys.contentType.sys.id === "staffMember" ? "Contentful_StaffMember" : "Contentful_Article";
        const entry: Contentful_Article | Contentful_StaffMember | any = {
          __typename: __typename,
          sys: result.sys.id,
          title:
            __typename === "Contentful_StaffMember"
              ? `${result.title ? result.title + " " : ""}${result.firstName} ${result.lastName}`
              : result.title,
          descript: result.jobTitle,
          urlSlug: result.urlSlug,
          publishDate: result.publishDate ?? null,
          eventDate: result.eventDate ?? null,
          cardImage:
            (result.cardImage && {
              title: result.cardImage.title ?? "",
              description: result.cardImage.description ?? "",
              contentType: result.cardImage.file.contentType ?? "",
              fileName: result.cardImage.file.fileName ?? "",
              url: result.cardImage.file.url ?? "",
              width: result.cardImage.file.details.width ?? "",
              height: result.cardImage.file.details.height ?? "",
            }) ??
            (result.photo && {
              title: result.photo.title ?? "",
              description: "",
              contentType: result.photo.file.contentType ?? "",
              fileName: result.photo.file.fileName ?? "",
              url: result.photo.file.url ?? "",
              width: result.photo.file.details.width ?? "",
              height: result.photo.file.details.height ?? "",
            }) ??
            null,
          contentType: contentTypes
            ? contentTypes.items.find((contentType: any) => contentType.sys.id === result.contentType.sys.id)
            : null,
        };

        return <Tile entry={entry} size={TileSize.Square} count={key} key={key} />;
      })}
    </div>
  );
}

export default Results;
