import React from "react";
import classnames from "classnames";

function Heading3({ className, children }: { className: string; children: React.ReactNode }) {
  return (
    <h3
      className={classnames(["text-2xl", "font-medium", "md:leading-tight", "lg:text-3xl", "mb-4", "last:mb-0", className])}
    >
      {children}
    </h3>
  );
}

Heading3.defaultProps = {
  className: "",
};

// export default Heading3;
export default React.memo(Heading3);
