import React, { useState } from "react";
import * as Yup from "yup";
import fetch from "isomorphic-unfetch";
import { Formik, Form as FormikForm, Field } from "formik";
import { useIntl } from "gatsby-plugin-intl";
import TextInput from "../../components/fields/input";
import TextAreaInput from "../../components/fields/textarea";
import RadioInput from "../../components/fields/radio";
import { getFormData, ErrorDisplay, Success } from "./utils";
import Spinner from "../../../static/images/icons/spinner.svg";

interface FormValues {
  "form-name": string;
  name: string;
  email: string;
  organisation: string;
  eventName: string;
  eventLocation: string;
  eventDate: string;
  eventWebsite: string;
  eventAudienceSize: string;
  eventAudienceDescription: string;
  preferredPresentationStyle: string;
  otherPresentationStyle: string;
  speakingAbout: string;
  additionalInfo: string;
  expensesInfo: string;
  botField: string;
}

function Form(props: any) {
  const intl = useIntl();
  const [toggled, setToggled] = useState<boolean>(false);

  return (
    <div className="container mx-auto px-6 my-10">
      {props.errors.general && <ErrorDisplay>{props.errors.general}</ErrorDisplay>}

      {/* Used for Netlify forms processing */}
      <form
        action={`/${intl.locale}/form-submission`}
        method="POST"
        name="SpeakingOpportunities"
        data-netlify="true"
        netlify-honeypot="botField"
      >
        <input type="hidden" name="botField" />
        <input type="hidden" name="name" />
        <input type="hidden" name="email" />
        <input type="hidden" name="organisation" />
        <input type="hidden" name="eventName" />
        <input type="hidden" name="eventLocation" />
        <input type="hidden" name="eventDate" />
        <input type="hidden" name="eventWebsite" />
        <input type="hidden" name="eventAudienceSize" />
        <input type="hidden" name="eventAudienceDescription" />
        <input type="hidden" name="preferredPresentationStyle" />
        <input type="hidden" name="otherPresentationStyle" />
        <input type="hidden" name="speakingAbout" />
        <input type="hidden" name="additionalInfo" />
        <input type="hidden" name="expensesInfo" />
      </form>

      <FormikForm noValidate={true}>
        <Field type="hidden" name="form-name" value={props.values["form-name"]} />

        <div className="space-y-8 max-w-md">
          <div className="hidden">
            <TextInput label="" name="botField" formProps={props} />
          </div>
          <TextInput label="Name" name="name" autoComplete="name" formProps={props} />
          <TextInput label="Email" name="email" type="email" autoComplete="email" formProps={props} />
          <TextInput label="Organisation" name="organisation" autoComplete="organization" formProps={props} />
          <TextInput label="Event name" name="eventName" formProps={props} />
          <TextInput label="Event location" name="eventLocation" formProps={props} />
          <TextInput label="Event date" name="eventDate" formProps={props} />
          <TextInput label="Event website" name="eventWebsite" formProps={props} />
          <TextInput label="Event audience size" name="eventAudienceSize" formProps={props} />
          <TextInput label="Description of audience" name="eventAudienceDescription" formProps={props} />
          <div className="text-lg">
            <label className="block mb-4" htmlFor="preferredPresentationStyle">
              <span className="text-xl font-medium">Preferred presentation style</span>
            </label>
            <div className="space-y-2">
              <RadioInput
                label="Key note"
                name="preferredPresentationStyle"
                formProps={props}
                onClick={() => setToggled(false)}
              />

              <RadioInput
                label="Panel"
                name="preferredPresentationStyle"
                formProps={props}
                onClick={() => setToggled(false)}
              />

              <RadioInput
                label="Webinar"
                name="preferredPresentationStyle"
                formProps={props}
                onClick={() => setToggled(false)}
              />

              <RadioInput
                label="Workshop"
                name="preferredPresentationStyle"
                formProps={props}
                onClick={() => setToggled(false)}
              />

              <RadioInput
                label="Other"
                name="preferredPresentationStyle"
                formProps={props}
                onClick={() => setToggled(true)}
              />
              {props.errors.preferredPresentationStyle && props.touched.preferredPresentationStyle ? (
                <ErrorDisplay>{props.errors.preferredPresentationStyle}</ErrorDisplay>
              ) : null}
            </div>
          </div>
          <div className={`pt-4 ${toggled ? "block" : "hidden"}`}>
            <TextAreaInput label="Other presentation style" name="otherPresentationStyle" formProps={props} />
          </div>
          <div className="pt-4">
            <TextAreaInput label="What do you want us to speak about?" name="speakingAbout" formProps={props} />
          </div>
          <div className="pt-5">
            <TextAreaInput label="Anything else we should know about your event?" name="additionalInfo" formProps={props} />
          </div>
          <div className="pt-5">
            <TextAreaInput
              label="Will expenses be covered for speaking at this event?"
              name="expensesInfo"
              formProps={props}
            />
          </div>
          <button
            type="submit"
            disabled={props.isSubmitting}
            className="disabled:opacity-50 relative leading-tight overflow-hidden text-lg opacity-100 w-full block py-4 px-6 font-semibold rounded-full text-white border-green-500 bg-green-500"
          >
            {!props.isSubmitting && <div>Submit Enquiry</div>}
            {props.isSubmitting && (
              <div className="flex items-center justify-center">
                <div className="mr-4">Submitting</div>
                <Spinner className="animate-spin h-4" />
              </div>
            )}
          </button>
        </div>
      </FormikForm>
    </div>
  );
}

export default function () {
  const intl = useIntl();
  const [submitted, setSubmitted] = useState(false);

  const initialValues: FormValues = {
    "form-name": "SpeakingOpportunities",
    name: "",
    email: "",
    organisation: "",
    eventName: "",
    eventLocation: "",
    eventDate: "",
    eventWebsite: "",
    eventAudienceSize: "",
    eventAudienceDescription: "",
    preferredPresentationStyle: "",
    otherPresentationStyle: "",
    speakingAbout: "",
    additionalInfo: "",
    expensesInfo: "",
    botField: "",
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: "formValidation.nameRequired" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "formValidation.emailInvalid" }))
      .required(intl.formatMessage({ id: "formValidation.emailRequired" })),
    organisation: Yup.string().required(intl.formatMessage({ id: "formValidation.organisationRequired" })),
    eventName: Yup.string().required(intl.formatMessage({ id: "formValidation.eventNameRequired" })),
    eventLocation: Yup.string().required(intl.formatMessage({ id: "formValidation.eventLocationRequired" })),
    eventDate: Yup.string().required(intl.formatMessage({ id: "formValidation.eventDateRequired" })),
    eventWebsite: Yup.string().required(intl.formatMessage({ id: "formValidation.eventWebsiteRequired" })),
    eventAudienceSize: Yup.string().required(intl.formatMessage({ id: "formValidation.eventAudienceSizeRequired" })),
    eventAudienceDescription: Yup.string().required(
      intl.formatMessage({ id: "formValidation.eventAudienceDescriptionRequired" })
    ),
    preferredPresentationStyle: Yup.string().required(
      intl.formatMessage({ id: "formValidation.preferredPresentationStyleRequired" })
    ),
    otherPresentationStyle: Yup.string().when("preferredPresentationStyle", {
      is: "Other",
      then: Yup.string().required(intl.formatMessage({ id: "formValidation.otherPresentationStyleRequired" })),
    }),
    speakingAbout: Yup.string().required(intl.formatMessage({ id: "formValidation.speakingAboutRequired" })),
    additionalInfo: Yup.string(),
    expensesInfo: Yup.string(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(
        values: any,
        actions: {
          setFieldError: (arg0: string, arg1: string) => void;
          setSubmitting: (arg0: boolean) => void;
        }
      ) => {
        fetch(`/${intl.locale}/form-submission`, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: getFormData(values),
        })
          .then((r) => {
            if (r.ok) {
              return r;
            } else {
              actions.setFieldError("general", intl.formatMessage({ id: "formValidation.unexpectedError" }));

              setTimeout(() => {
                actions.setFieldError("general", "");
              }, 5000);
              return Promise.reject(r.statusText);
            }
          })
          .then(() => {
            setSubmitted(true);
            const isSSR = typeof window === "undefined";
            if (!isSSR) {
              window.scrollTo({
                top:
                  document && document.getElementById("form-success-message")
                    ? document.getElementById("form-success-message").offsetTop - 90
                    : 120,
                behavior: "smooth",
              });
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(controls: any) => (
        <>
          {!submitted && <Form {...controls} />}
          {submitted && <Success />}
        </>
      )}
    </Formik>
  );
}
