import React from "react";
import classnames from "classnames";

function Heading2({ className, children }: { className: string; children: React.ReactNode }) {
  return (
    <h2 className={classnames(["text-4xl", "font-medium", "leading-tight", "lg:text-5xl", "mb-4", "last:mb-0", className])}>
      {children}
    </h2>
  );
}

Heading2.defaultProps = {
  className: "",
};

// export default Heading2;
export default React.memo(Heading2);
