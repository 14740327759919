import React from "react";
import { Field } from "formik";

interface Props {
  label: string;
  name: string;
  type?: string;
  formProps?: any;
  onClick?: () => void;
  [k: string]: any;
}

export default function ({ label, name, type = "checkbox", formProps, onClick = () => {}, ...props }: Props) {
  return (
    <div>
      <label className="inline-flex items-center">
        <Field
          type={type}
          name={name}
          value={label}
          onClick={onClick}
          className="disabled:opacity-50 text-slate-400 bg-slate-200 border-0 shadow-inner focus:border-0 focus:outline-none focus:ring-0 focus:shadow-0"
          disabled={formProps.isSubmitting}
          {...props}
        />
        <span className="ml-2 leading-tight">{label}</span>
      </label>
    </div>
  );
}
