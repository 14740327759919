import React from "react";
import classnames from "classnames";

function Quote({ className, children }: { className: string; children: React.ReactNode }) {
  return (
    <blockquote
      className={classnames([
        "border-solid border-l-4 border-green-500 text-green-500 pt-1 pl-4 text-2xl font-medium",
        "md:pl-8 md:text-3xl md:leading-snug",
        "rich-text-quote",
        className,
      ])}
    >
      {children}
    </blockquote>
  );
}

Quote.defaultProps = {
  className: "",
};

// export default Quote;
export default React.memo(Quote);
