import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby-plugin-intl";
import { Contentful_SiteSettings, Contentful_Article, Contentful_ContentTypeCollection } from "graphql-types";
import get from "lodash/get";
import algoliasearch from "algoliasearch/lite";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import Tile from "./tile/entry";
import Heading2 from "./rich-text-elements/heading-2";
import { TileSize } from "./tile/types";
import { CONTENT_TYPE } from "../utils/constants";

const client = algoliasearch(process.env.ALGOLIA_APP_ID ?? "", process.env.ALGOLIA_APP_SEARCH_KEY ?? "");
const index = client.initIndex(process.env.ALGOLIA_ARTICLES_INDEX ?? "");

function SeeMoreArticles({ url }: { url: string }) {
  return (
    <Link to={url} className="underline text-lg md:text-base font-medium whitespace-nowrap">
      See more
    </Link>
  );
}

function RelatedArticles({ article }: { article: Contentful_Article }) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<any>([]);
  const contentTypeData = useStaticQuery(filterQuery);
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);
  const contentTypes: Contentful_ContentTypeCollection = get(contentTypeData, "contentful.contentTypeCollection");
  let filters: any[] = [];
  const researchAreaFilters: any[] = [];
  const sectorFilters: any[] = [];

  const queryFilters: any[] = [];

  // We don't want to filter by content type anymore...
  // filters.push([`contentType.sys.id:${article.contentType.sys.id}`]);

  article.researchAreasCollection?.items.map((researchArea: any) => {
    if (!researchArea) return null;
    researchAreaFilters.push(`researchAreas.sys.id:${researchArea.sys.id}`);
    queryFilters.push(`researchAreasCollection=${researchArea.sys.id}`);
  });

  article.sectorsCollection?.items.map((sector: any) => {
    if (!sector) return null;
    sectorFilters.push(`sectors.sys.id:${sector.sys.id}`);
    queryFilters.push(`sectorsCollection=${sector.sys.id}`);
  });

  filters.push(researchAreaFilters);
  filters.push(sectorFilters);
  filters = filters.filter((x: any) => x !== null).filter((e) => e.length) ?? [];

  if (!filters.length) return null;
  const relatedArticlesURL = `/${SiteSettings.primaryCollection?.urlSlug}?${queryFilters.join("&")}`;

  useEffect(() => {
    index
      .search("", {
        filters: `NOT objectID:${article.sys.id} AND NOT contentType.sys.id:${CONTENT_TYPE.EVENT}`,
        facetFilters: filters,
        hitsPerPage: 3,
      })
      .then(({ hits }: { hits: any }) => {
        setLoading(false);
        setResults(hits.filter((x: any) => x !== null));
      })
      .catch(() => {});
  }, []);

  if (!results || !results.length) return null;

  return (
    <>
      <hr className="my-10" />
      <div className="flex justify-between items-center mb-14 lg:mb-24">
        <div>
          <Heading2>Related articles</Heading2>
        </div>
        <div className="hidden md:block">
          {SiteSettings.primaryCollection && <SeeMoreArticles url={relatedArticlesURL} />}
        </div>
      </div>
      <div className="flex">
        <div className="md:grid md:grid-cols-3 lg:grid-cols-3 md:gap-6 w-full">
          {results.length > 0 &&
            results.map((result: any, key: number) => {
              const entry: Contentful_Article | any = {
                __typename: "Contentful_Article",
                sys: result.sys.id,
                title: result.title,
                urlSlug: result.urlSlug,
                publishDate: result.publishDate ?? null,
                eventDate: result.eventDate ?? null,
                cardImage:
                  (result.cardImage && {
                    title: result.cardImage.title ?? "",
                    description: result.cardImage.description ?? "",
                    contentType: result.cardImage.file.contentType ?? "",
                    fileName: result.cardImage.file.fileName ?? "",
                    url: result.cardImage.file.url ?? "",
                    width: result.cardImage.file.details.width ?? "",
                    height: result.cardImage.file.details.height ?? "",
                  }) ??
                  null,
                contentType: contentTypes.items.find((contentType: any) => contentType.sys.id === result.contentType.sys.id),
              };

              return <Tile entry={entry} size={TileSize.Tile} count={key} key={key} />;
            })}
        </div>
      </div>
      <div className="md:hidden text-center py-4">
        {SiteSettings.primaryCollection && <SeeMoreArticles url={relatedArticlesURL} />}
      </div>
    </>
  );
}

// export default RelatedArticles;
export default React.memo(RelatedArticles);

const filterQuery = graphql`
  query RelatedQuery {
    contentful {
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          name
          singularName
        }
      }
    }
  }
`;
