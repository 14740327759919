import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Contentful_BlockSectorsCarouselItem } from "graphql-types";
import gsap from "gsap";

const SectorsCarouselNavList = ({
  activeIndex,
  items,
  fromBackgroundGradientColor,
  toBackgroundGradientColor,
  textColor,
  onClickItem,
}: {
  activeIndex: number;
  items: Contentful_BlockSectorsCarouselItem[];
  fromBackgroundGradientColor: string;
  toBackgroundGradientColor: string;
  textColor: string;
  onClickItem?: any;
}) => {
  const navItemMinWidth = 200;
  const initialState = {
    initialLoad: true,
  };
  const [state, setState] = useState(initialState);
  const navItemStyle = {
    minWidth: `${navItemMinWidth}px`,
  };

  const getItemIndex = function (n: number): string {
    n = n + 1;
    return `${n < 10 ? "0" + n : n}`;
  };

  const bar = useRef(null);
  useEffect(() => {
    let useStaticAnimation = true;
    if (state.initialLoad) {
      setState((prevState) => ({
        ...prevState,
        initialLoad: false,
      }));
    } else {
      useStaticAnimation = false;
    }

    let currentPositionX = navItemMinWidth / 2 + activeIndex * navItemMinWidth;

    if (useStaticAnimation) {
      gsap.set(bar.current, { x: -currentPositionX });
    } else {
      gsap.to(bar.current, { x: -currentPositionX, duration: 1, ease: "power3.out" });
    }

    return;
  }, [activeIndex]);

  return (
    <>
      <div className="relative w-full overflow-x-hidden text-center block xl:hidden">
        <div className="relative left-1/2">
          <ul ref={bar} className="flex flex-no-wrap justify-start select-none">
            {items &&
              items.map((item: Contentful_BlockSectorsCarouselItem, key: number) => {
                const itemClassName = classNames("flex-grow-0 flex-shrink-0 text-xl", {
                  "font-semibold": activeIndex == key,
                  "select-none": true,
                });
                return (
                  <li key={key} className={itemClassName}>
                    <div
                      className={`w-full cursor-pointer select-none ${textColor}`}
                      style={navItemStyle}
                      onClick={() => {
                        {
                          onClickItem && onClickItem(key);
                        }
                      }}
                    >
                      <span className="text-xs align-top">{getItemIndex(key)}</span> {item.heading}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
          <div
            className={`absolute top-0 left-0 w-1/6 h-full bg-gradient-to-l ${fromBackgroundGradientColor} ${toBackgroundGradientColor}`}
          ></div>
          <div
            className={`absolute top-0 right-0 w-1/6 h-full bg-gradient-to-r ${fromBackgroundGradientColor} ${toBackgroundGradientColor}`}
          ></div>
        </div>
      </div>
      <div className="relative w-full text-center hidden xl:block">
        <div className="container mx-auto">
          <ul className="flex flex-wrap justify-center block">
            {items &&
              items.map((item: Contentful_BlockSectorsCarouselItem, key: number) => {
                const itemClassName = classNames("text-lg", {
                  "font-semibold": activeIndex == key,
                  "mr-8": key < items.length - 1,
                });
                return (
                  <li key={key} className={itemClassName}>
                    <div
                      className={`cursor-pointer ${textColor}`}
                      onClick={() => {
                        {
                          onClickItem && onClickItem(key);
                        }
                      }}
                    >
                      <span className="text-xs align-top">{getItemIndex(key)} </span> {item.heading}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SectorsCarouselNavList;
