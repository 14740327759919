import { Contentful_ColourWay } from "graphql-types";

function useColourway(colourway: Contentful_ColourWay | null) {
  const tailwindClassName = colourway && colourway.label ? colourway.label.toString().toLowerCase() : null;

  if (!tailwindClassName) {
    return {
      tailwindClassName: null,
      backgroundColor: ``,
      foregroundColor: ``,
      textColor: `text-slate-500`,
    };
  }

  return {
    tailwindClassName: tailwindClassName,
    color: `${tailwindClassName}`,
    backgroundColor: `bg-${tailwindClassName}-200`,
    foregroundColor: `bg-${tailwindClassName}-100`,
    textColor: `text-${tailwindClassName}-500`,
  };
}

export default useColourway;
