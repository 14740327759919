import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { Contentful_Link } from "graphql-types";
import { default as InlineButton } from "../button";

function Button({
  id,
  forceCurrentColor,
  forceLargeButton,
}: {
  id: string;
  forceCurrentColor: boolean;
  forceLargeButton: boolean;
}) {
  const data = useStaticQuery(buttonQuery);
  const items: Contentful_Link[] = get(data, "contentful.linkCollection.items");
  const link = items.find((item: Contentful_Link) => item.sys.id === id);
  if (link) return <InlineButton {...link} forceCurrentColor={forceCurrentColor} forceLargeButton={forceLargeButton} />;
  return null;
}

Button.defaultProps = {
  forceCurrentColor: false,
};

// export default Button;
export default React.memo(Button);

const buttonQuery = graphql`
  query ButtonQuery {
    contentful {
      linkCollection(limit: 1000) {
        items {
          __typename
          sys {
            id
            publishedVersion
          }
          label
          externalUrl
          internalPageQueryString
          openInNewTab
          internalPage {
            ... on Contentful_Collections {
              sys {
                id
                publishedVersion
              }
              title
              urlSlug
            }
            ... on Contentful_Page {
              sys {
                id
                publishedVersion
              }
              title
              urlSlug
            }
            ... on Contentful_Article {
              sys {
                id
                publishedVersion
              }
              title
              urlSlug
            }
            ... on Contentful_StaffMember {
              sys {
                id
                publishedVersion
              }
              title
              urlSlug
            }
          }
          variant
          buttonSize
          file {
            title
            url
            contentType
            fileName
            size
          }
        }
      }
    }
  }
`;
