import React from "react";
import { Contentful_BlockFullWidthImage } from "graphql-types";
import Image from "../image";

function FullWidthImageBlock(props: Contentful_BlockFullWidthImage) {
  if (!props?.sys) return null;

  if (!props.image || !props.image.image || !props.image.image.width || !props.image.image.height) return null;

  const isTransparent = props.transparent;

  return (
    <section className={`${!isTransparent && "bg-black"} pointer-events-none`}>
      <Image
        image={props.image.image}
        alt={props.image.image.title ?? ""}
        className={`flex max-w-full h-screen ${!isTransparent && "bg-black blend-screen"}`}
        loading="lazy"
        maxWidth={4000}
        type={"fluid"}
        objectFit="cover"
        focalPoint={props.image.focalPoint}
      />
    </section>
  );
}

// export default FullWidthImageBlock;
export default React.memo(FullWidthImageBlock);
