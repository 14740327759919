import React from "react";
import { Contentful_BlockFeatured } from "graphql-types";
import useColourway from "../../hooks/useColourway";
import Tile from "../tile/entry";
import { TileSize } from "../tile/types";

function Featured(props: Contentful_BlockFeatured) {
  if (!props?.sys) return null;

  if (!props.featuredCollection?.items.length) return null;
  const { backgroundColor } = useColourway(props.colourway || null);
  const featuredItems = props.featuredCollection?.items.filter((x: any) => x !== null) ?? [];

  return (
    <section className={`${backgroundColor}`}>
      <h3 className="sr-only">Featured</h3>
      <section className="container mx-auto px-6 py-10">
        <div className="w-full md:flex md:flex-no-wrap md:space-x-6">
          {featuredItems.slice(0, 2).map((item: any, key: number) => (
            <Tile entry={item} size={TileSize.Featured} isHighlighted={true} count={key} key={key} />
          ))}
        </div>
        <div className="md:flex md:flex-no-wrap w-full md:space-x-6 md:-mt-4">
          {featuredItems.slice(2).map((item: any, key: number) => (
            <Tile entry={item} size={TileSize.Featured} isHighlighted={false} count={key} key={key} />
          ))}
        </div>
      </section>
    </section>
  );
}

// export default Featured;
export default React.memo(Featured);
