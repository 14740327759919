import React from "react";
import { graphql } from "gatsby";
import Cookies from "js-cookie";
import { filterDataByLocation } from "../../utils/filters";

const SectorsCarouselSwiper = ({
  children,
  minThresholdToSwiper,
  onNext,
  onPrev,
  ...rest
}: {
  children: any;
  minThresholdToSwiper: number;
  onNext: any;
  onPrev: any;
}) => {
  const location = Cookies.get("location");
  rest = filterDataByLocation(rest, location);

  if (!rest) return null;

  let initialTouchPosition: any = {
    x: 0,
    y: 0,
  };
  let currentTouchPosition: any = {
    x: 0,
    y: 0,
  };

  const onTouchEnd = () => {
    window.removeEventListener("touchend", onTouchEnd);
    window.removeEventListener("touchmove", onTouchMove);

    if (Math.abs(currentTouchPosition.x) > minThresholdToSwiper) {
      if (currentTouchPosition.x < 0) {
        onNext();
      } else {
        onPrev();
      }
    }
  };

  const onTouchMove = (event: any) => {
    let touch = event.touches[0] || event.changedTouches[0];
    currentTouchPosition = {
      x: touch.pageX - initialTouchPosition.x,
      y: touch.pageY - initialTouchPosition.y,
    };
  };

  const onTouchStart = (event: any) => {
    let touch = event.touches[0] || event.changedTouches[0];
    initialTouchPosition = { x: touch.pageX, y: touch.pageY };
    currentTouchPosition = {
      x: touch.pageX - initialTouchPosition.x,
      y: touch.pageY - initialTouchPosition.y,
    };
    window.addEventListener("touchend", onTouchEnd);
    window.addEventListener("touchmove", onTouchMove);
  };

  return (
    <>
      <div className="w-full" onTouchStart={onTouchStart}>
        {children}
      </div>
    </>
  );
};

export default SectorsCarouselSwiper;
