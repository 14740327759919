import React from "react";
import classnames from "classnames";
import RichText from "../rich-text";
import { Contentful_BlockThreeColumn } from "graphql-types";
import { BlockSpaceJson, SpacerDefaultProps, translateBlockSpacing } from "../../utils/translate-cf-spacing";

function ThreeColumn(props: Contentful_BlockThreeColumn) {
  if (!props.sys) return null;

  const isTight = props.isTight ?? false;

  const topSpaceDefault: SpacerDefaultProps = {
    sm: isTight ? 3 : 6,
    md: isTight ? 6 : 12,
    lg: isTight ? 8 : 24,
  };

  const bottomSpaceDefault: SpacerDefaultProps = {
    sm: isTight ? 3 : 6,
    md: isTight ? 6 : 12,
    lg: isTight ? 8 : 24,
  };

  const topSpaceClasses = translateBlockSpacing("top", props.topSpace as BlockSpaceJson, topSpaceDefault);
  const bottomSpaceClasses = translateBlockSpacing("bottom", props.bottomSpace as BlockSpaceJson, bottomSpaceDefault);

  return (
    <section className={classnames(["flex items-center text-slate-400", topSpaceClasses, bottomSpaceClasses])}>
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-10 lg:gap-20 text-base">
          {props.contentCollection?.items.map((item, key) => {
            if (!item || !item.content) return null;
            return (
              <div key={key}>
                <RichText {...item?.content} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

// export default ThreeColumn;
export default React.memo(ThreeColumn);
