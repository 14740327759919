import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import classnames from "classnames";
import { Link } from "gatsby-plugin-intl";
import { Contentful_BlockPeople, Contentful_StaffMember, Maybe } from "graphql-types";
import Heading2 from "../rich-text-elements/heading-2";
import Image from "../image";
import Button from "../button";

type StaffMembers = Array<Maybe<Contentful_StaffMember>>;

function StaffMember(props: Contentful_StaffMember) {
  const fullName = `${props.firstName} ${props.lastName}`;

  return (
    <li className="pb-4">
      <Link to={`/staff/${props.urlSlug}`} title={fullName.trim() ?? ""}>
        <div className="font-medium leading-none">
          {props.photo && (
            <div className="pb-4">
              <div className="overflow-hidden rounded-3xl">
                <Image image={props.photo} alt={fullName.trim() ?? ""} type={"fluid"} className="w-full" />
              </div>
            </div>
          )}
          <div className="pb-4">
            {fullName.trim() && (
              <h2 className="text-2xl mb-2">
                {props.title} {fullName}
              </h2>
            )}
            {props.jobTitle && <h3 className="font-normal text-sm">{props.jobTitle}</h3>}
          </div>
        </div>
      </Link>
    </li>
  );
}

function CallToAction(props: Contentful_BlockPeople) {
  if (!props.callToAction) return null;
  return <Button {...props.callToAction} />;
}

function StaffMemberList({ list, staffMembers }: { list: StaffMembers; staffMembers: StaffMembers }) {
  if (!list.length) return null;
  return (
    <ul>
      {list.map((highlightedPerson, key) => {
        if (!highlightedPerson) return null;
        const staffMember = staffMembers.find((p) => p?.sys.id == highlightedPerson.sys.id) ?? null;
        if (!staffMember) return null;
        return <StaffMember key={key} {...staffMember} />;
      })}
    </ul>
  );
}

function filterItems(item: Contentful_StaffMember, props: Contentful_BlockPeople) {
  return props.filteredCollection?.items.find((filter) => filter?.sys?.id === item?.sys?.id ?? null) ? true : false;
}

function People(props: Contentful_BlockPeople) {
  if (!props.sys) return null;

  const maxPeople: number = 5;
  const data = useStaticQuery(peopleQuery);
  const staffMembers: StaffMembers = get(data, "contentful.staffMemberCollection.items");
  const highlightedStaffMembers: StaffMembers = get(props, "highlightedPeopleCollection.items");
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<any>(null);

  useEffect(() => {
    const randomizedStaffMembers: StaffMembers = [...staffMembers].sort(() => 0.5 - Math.random());
    const filtered = uniqBy(
      [...randomizedStaffMembers].filter((staffMember) => {
        let found: boolean = false;

        // We need to find one of the filters in a staff member to be accepted
        staffMember?.researchAreasCollection?.items.forEach((item) => {
          if (found || !item) return;
          found = filterItems(item, props);
        });

        staffMember?.sectorsCollection?.items.forEach((item) => {
          if (found || !item) return;
          found = filterItems(item, props);
        });

        staffMember?.departmentsCollection?.items.forEach((item) => {
          if (found || !item) return;
          found = filterItems(item, props);
        });

        highlightedStaffMembers.forEach((item) => {
          if (item?.sys.id === staffMember?.sys.id) found = false;
        });

        return found;
      }),
      "sys.id"
    );

    setFilteredStaffMembers(filtered);
  }, []);

  if (!filteredStaffMembers) return null;

  const remainingPeopleCount = maxPeople - (highlightedStaffMembers.length ?? 0);
  let jumboSizeStaffMembers: StaffMembers = [];

  if (highlightedStaffMembers.length > 2)
    jumboSizeStaffMembers = highlightedStaffMembers.slice(2, highlightedStaffMembers.length);

  const remainingStaffMembers: StaffMembers = jumboSizeStaffMembers.concat(
    filteredStaffMembers.slice(0, remainingPeopleCount) ?? []
  );
  const availablePeople: StaffMembers = highlightedStaffMembers.slice(0, 2).concat(remainingStaffMembers);
  const firstPeopleList: StaffMembers = availablePeople.slice(0, 2);
  const secondPeopleList: StaffMembers = availablePeople.slice(2, availablePeople.length);

  function Heading() {
    if (!props.heading) return null;
    return (
      <>
        <div className="md:mb-6">
          <Heading2>{props.heading}</Heading2>
        </div>
        <div className="hidden md:block">
          <CallToAction {...props} />
        </div>
      </>
    );
  }

  return (
    <section className={classnames(["container mx-auto px-6 py-4 md:py-8"])}>
      <div className="flex flex-no-wrap items-center justify-between">
        <Heading />
      </div>
      <div className="pt-4">
        <section className="md:flex md:flex-no-wrap md:flex-row-reverse">
          <div className="md:w-3/5 md:ml-6">
            <StaffMemberList list={firstPeopleList} staffMembers={staffMembers} />
          </div>
          <div className="md:w-2/5">
            <StaffMemberList list={secondPeopleList} staffMembers={staffMembers} />
          </div>
        </section>
      </div>
      <div className="md:hidden text-center pb-6">
        <CallToAction {...props} />
      </div>
    </section>
  );
}

// export default People;
export default React.memo(People);

const peopleQuery = graphql`
  query peopleQuery {
    contentful {
      staffMemberCollection(limit: 300) {
        items {
          sys {
            id
            spaceId
            publishedVersion
          }

          firstName
          lastName
          title
          jobTitle
          urlSlug
          bio
          emailAddress
          phoneNumber
          linkedInUrl
          orcidUrl
          photo {
            ...imageFields
          }
          researchAreasCollection(limit: 10) {
            items {
              sys {
                id
                publishedVersion
              }
              label
            }
          }
          sectorsCollection(limit: 10) {
            items {
              sys {
                id
                publishedVersion
              }
              label
            }
          }
          departmentsCollection(limit: 10) {
            items {
              sys {
                id
                publishedVersion
              }
              label
            }
          }
        }
      }
      entryCollection {
        items {
          ... on Contentful_BlockPeople {
            includeLocationCollection(limit: 3) {
              items {
                code
              }
            }
            excludeLocationCollection(limit: 3) {
              items {
                code
              }
            }
          }
        }
      }
    }
  }
`;
