import React, { ReactFragment } from "react";
import { Contentful_BlockForm } from "graphql-types";
import NewBusinessForm from "../forms/new-business";
import ResearchCollaborationForm from "../forms/research-collaboration";
import MediaEnquiryForm from "../forms/media-enquiry";
import SpeakingOpportunityForm from "../forms/speaking-opportunity";

function Form(props: Contentful_BlockForm) {
  if (!props.sys) return null;

  let DynamicComponent: ReactFragment | null | any = React.Fragment;

  switch (props.formType) {
    case "New Business":
      DynamicComponent = NewBusinessForm;
      break;
    case "Research Collaboration":
      DynamicComponent = ResearchCollaborationForm;
      break;
    case "Media Enquiry":
      DynamicComponent = MediaEnquiryForm;
      break;
    case "Speaking Opportunity":
      DynamicComponent = SpeakingOpportunityForm;
      break;
    default:
      break;
  }

  return <DynamicComponent />;
}

// export default Form;
export default React.memo(Form);
