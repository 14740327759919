import React from "react";
import { Contentful_BlockSplashImageText } from "graphql-types";
import RichText from "../rich-text";
import Image from "../image";

function SplashImageText(props: Contentful_BlockSplashImageText) {
  if (!props.sys) return null;

  const isTransparent = props.transparent;

  function Background() {
    if (!props.image || !props.image.image) return null;
    return (
      <Image
        image={props.image.image}
        alt={props.image.image.title ?? ""}
        className="inset-0 pointer-events-none z-0"
        maxWidth={4000}
        type={"fluid"}
        style={{ position: "absolute" }}
        loading="lazy"
        objectFit="cover"
        focalPoint={props.image.focalPoint}
      />
    );
  }

  return (
    <section
      className={`flex relative bg-cover overflow-hidden py-40 lg:px-20 lg:py-40 xl:py-48 ${
        !isTransparent && "bg-slate-200"
      }`}
    >
      <Background />
      <div className="relative container mx-auto px-6">
        <div>
          <div className={`relative flex w-full lg:w-2/4 text-white`}>
            <div className="font-medium leading-relaxed splash-image-text">
              {props.content && <RichText {...props.content} forceLargeButton={true} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// export default SplashImageText;
export default React.memo(SplashImageText);
