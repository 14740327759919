import React, { useEffect, useRef, useContext, useState } from "react";
import isEmpty from "lodash/isEmpty";
import useSmoothScroll from "react-smooth-scroll-hook";
import Link from "./link";
import { Contentful_SiteSettings, Contentful_NavigationItem, Maybe } from "graphql-types";
import { SiteSettingsContext } from "../contexts/SiteSettingsContext";
import useColourway from "../hooks/useColourway";

// Import content blocks
import BLOCKS, { BlockType } from "./blocks";
import classNames from "classnames";
import { findSideNavItems } from "../utils/filters";
import { BlockItemProps } from "./block-loader";

function NavigationLink({
  children,
  ...item
}: {
  children: any;
  index: number;
  label: string;
  items: any[];
  isActive: boolean;
}) {
  return (
    <li>
      <div className={`relative text-white ${item?.index === 0 ? "mt-1 mb-4" : "my-4"}`}>
        <Link {...item} className="flex flex-no-wrap items-center text-base font-normal">
          <div
            className={`relative rounded-full mr-2 flex items-center justify-center ${
              isEmpty(item.items) ? "opacity-0" : "opacity-25"
            }`}
            style={{ width: "8px", height: "8px" }}
          >
            <div className="relative bg-black rounded-full" style={{ width: "4px", height: "4px" }}></div>
          </div>
          <div className={`leading-tight ${item.isActive ? "font-semibold" : ""}`}>{item.label}</div>
        </Link>
        {children}
      </div>
    </li>
  );
}

function Full({ children, firstBlockRichText }: { children: any; firstBlockRichText?: boolean }) {
  return (
    <div className="relative">
      <div className={`relative w-full ${!firstBlockRichText ? "pointer-events-auto" : ""}`}>{children}</div>
    </div>
  );
}

export function Partial({ children }: { children: any }) {
  const { backgroundColor } = useColourway(children.props.colourway || null);

  return (
    <div className={`relative ${backgroundColor}`}>
      <div className="container mx-auto">
        <div className="lg:ml-64 pointer-events-auto">{children}</div>
      </div>
    </div>
  );
}

function Wrap({
  children,
  blockName,
  blockCount,
  firstBlockRichText,
}: {
  children: any;
  blockName: string;
  blockCount: any;
  firstBlockRichText: boolean;
}) {
  switch (blockName) {
    case "Aggregator":
    case "Featured":
    case "LatestNews":
    case "People":
    case "PeopleSearch":
    case "RawHtml":
    case "Infographic":
    case "Tiles":
    case "TwoColumn":
    case "Video":
    case "Form":
    case "InPageNavigation":
    case "ThreeColumn":
    case "Partners":
      return <Partial>{children}</Partial>;

    case "CircularImage":
    case "FullWidthImage":
    case "GetInTouch":
    case "ImageColourBlock":
    case "ImageText":
    case "RichText":
    case "SectorsCarousel":
    case "SplashImageText":
    case "TwoCircleImage":
      return <Full firstBlockRichText={firstBlockRichText}>{children}</Full>;

    case "Hero":
      if (blockCount > 0) {
        return <Partial>{children}</Partial>;
      } else {
        return <Full>{children}</Full>;
      }

    default:
      return null;
  }
}

function AnchorBlock(props: any) {
  if (!props || !props.__typename) return null;
  const typename = props.__typename === "Contentful_BlockCaseStudies" ? "Aggregator" : props.__typename;
  const blockName: BlockType = typename.replace(/^Contentful_Block/gi, "");
  const DynamicComponent = BLOCKS[blockName] ?? null;
  if (!DynamicComponent) return null;

  return (
    <Wrap blockName={blockName} blockCount={props.index} firstBlockRichText={props.firstBlockRichText}>
      <DynamicComponent {...props} sidebar={true} />
    </Wrap>
  );
}

function isRenderable(block: any, key: number) {
  if (!block || !block.sys || !block.sys.id || !block.__typename) return null;
  return block;
}

function isLinkable(block: any, key: number) {
  if (!isRenderable(block, key) || (!block.blockLabel && !block.label && !block.heading)) return null;
  return block;
}

function SideNavAnchor({ pageId, blocks }: { pageId?: string; blocks: any }) {
  const isSSR = typeof window === "undefined";
  const hasResizeObserverSupport = !isSSR && typeof window.ResizeObserver === "function";
  const [navTopPosition, setNavTopPosition] = useState(0);
  const trackerNav = useRef<HTMLDivElement>(null);
  const trackerParent = useRef<HTMLDivElement>(null);
  const tracker = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const sideNavRef = useRef<HTMLDivElement>(null);
  const SiteSettings: Contentful_SiteSettings = useContext(SiteSettingsContext);

  const sideNavItems = findSideNavItems(SiteSettings.primaryNavigationCollection?.items, pageId || "");

  type NavigationItem = Contentful_NavigationItem & {
    items?: any[];
    isActive?: boolean;
    expandSubNav?: boolean;
  };

  const navigationItems =
    sideNavItems?.childrenCollection?.items
      .map((item: NavigationItem) => {
        item.isActive = item?.internalPage?.sys.id === pageId;

        if (!item || isEmpty(item?.childrenCollection?.items)) return item;

        if (item?.childrenCollection?.items?.length) {
          item.childrenCollection?.items.map((child: any) => {
            if (!child) return;
            child.isActive = child.internalPage?.sys.id === pageId;
          });
        }

        return item;
      })
      .map((item: NavigationItem) => {
        item.expandSubNav =
          !!item?.childrenCollection?.items.find((x: any) => x?.isActive) ||
          ((item?.childrenCollection?.items || []).length > 0 && pageId === item?.internalPage?.sys.id);

        return item;
      })
      .filter((x: any) => x !== null) || [];

  const sections: string[] = [];

  blocks.map((block: any, key: number) => {
    if (!isLinkable(block, key)) return null;
    if (block.invisibleHeading === false) return null;
    sections.push(`s-${block.sys.id}`);
  });

  const ref = useRef(!isSSR ? document.documentElement : null);
  const heroRef = useRef(!isSSR ? document.documentElement : null);
  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 100,
    direction: "y",
  });

  function updateTracker(index = 0) {
    if (trackerParent.current && tracker.current) {
      const height = trackerParent.current.offsetHeight / sections.length;
      tracker.current.style.height = `${height}px`;
      tracker.current.style.marginTop = `${height * index}px`;
    }
  }

  function AnchorNav(props: { navigationItem: any }) {
    const { navigationItem } = props;
    const items =
      blocks
        .map((block: any, key: number) => {
          if (!isLinkable(block, key)) return null;
          if (block.invisibleHeading === false) return null;
          return block;
        })
        .filter((x: any) => x !== null) ?? [];
    if (isEmpty(items)) return null;

    const hasChildren = navigationItem.childrenCollection.items.filter((x: any) => x).length > 0;

    const navContainerClasses = classNames("relative flex flex-no-wrap", {
      "my-4 ml-4": hasChildren,
    });
    return (
      <div className={navContainerClasses}>
        <div ref={trackerParent} className="relative w-1 mr-4">
          <div className="absolute top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden">
            {/*<div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-white opacity-25"></div>*/}
            {/*<div className="h-4 absolute top-0 left-0 right-0 bg-white rounded-full transition-all duration-300 ease-in-out"></div>*/}
          </div>
        </div>
        <div className="relative" ref={trackerNav}>
          {navigationItem.childrenCollection.items
            .filter((x: any) => x)
            .map((item: any, key: number) => {
              const linkClassName = classNames("nav-link text-sm font-normal leading-none hover:active", {
                "first-item": key === 0,
                "last-item": key === navigationItem?.childrenCollection.items.filter((x: any) => x).length - 1,
              });

              return (
                <React.Fragment key={key}>
                  <li className="relative py-1.5 space-y-3 leading-none">
                    <Link activeClassName="active" className={linkClassName} {...item}>
                      {item.label}
                    </Link>
                  </li>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  }

  useEffect(() => {
    let ob: any;
    let hob: any;

    if (!hasResizeObserverSupport) {
      return;
    }

    if (trackerParent.current) {
      ob = new ResizeObserver((entries: any) => {
        updateTracker();
      });
      ob.observe(trackerParent.current);
    }

    if (heroRef && heroRef.current) {
      hob = new ResizeObserver(() => {
        if (heroRef && heroRef.current) setNavTopPosition(heroRef.current.clientHeight + 100);
      });
      hob.observe(heroRef.current);
    }

    return () => {
      if (ob && trackerParent.current) ob.unobserve(trackerParent.current);
      if (hob && heroRef && heroRef.current) hob.unobserve(heroRef.current);
    };
  }, []);

  // extract the first block if it is a hero block
  const heroBlock = blocks.find((block: any) => block?.__typename === "Contentful_BlockHero");
  const contentBlocks = blocks.filter((block: any) => block?.__typename !== "Contentful_BlockHero");

  useEffect(() => {
    const sideBarRefCurrent = sideBarRef.current;
    if (sideBarRefCurrent) {
      const heroElement = document.getElementById(`s-${heroBlock.sys.id}`);
      const firstElement = document.getElementById(`s-${contentBlocks[0].sys.id}`);
      // search for section element in firstElement
      const firstSection = firstElement?.querySelector("section");
      // get first div element in firstSection
      const firstDiv = firstSection?.querySelector("div");
      if (firstDiv && heroElement) {
        const bottomOfHero = heroElement.getBoundingClientRect().bottom;
        const topOfFirstDiv = firstDiv.getBoundingClientRect().top;
        const space = topOfFirstDiv - bottomOfHero;

        sideBarRefCurrent.style.top = `${space}px`;
      }
    }
  }, []);

  const hasFullWidthBlock = contentBlocks.some((block: any) => {
    if (!block || !block.__typename) return false;

    const typename = block.__typename === "Contentful_BlockCaseStudies" ? "Aggregator" : block.__typename;
    const blockName: BlockType = typename.replace(/^Contentful_Block/gi, "");

    return ["FullWidthImage", "ImageText", "ImageColourBlock"].includes(blockName);
  });

  return (
    <>
      {heroBlock && (
        <section
          id={`s-${heroBlock.sys.id}`}
          datatype={heroBlock.__typename}
          ref={heroRef}
          className={`text-slate-400 `}
          data-section-index={sections.indexOf(`s-${heroBlock.sys.id}`) ?? null}
          key={heroBlock}
        >
          <AnchorBlock {...heroBlock} hasSideNav={navigationItems?.length || false} />
        </section>
      )}
      <div className="relative w-full">
        {/*TODO::Temporary Fix - No Sticky Functionality*/}
        <div ref={sideBarRef} className="relative w-full">
          <section className="container mx-auto">
            <div className={"absolute"}>
              <div className="relative lg:w-52 z-50 blend-difference">
                <div>
                  <div className="border border-solid border-transparent">
                    <div className="transition-all duration-300 ease-in-out">
                      <ul>
                        {navigationItems.map((item: any, key: number) => (
                          <NavigationLink key={key} index={key} {...item}>
                            {item.expandSubNav && <AnchorNav navigationItem={item} />}
                          </NavigationLink>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/*TODO::Kept previous code for reference*/}
        {false && (
          <div
            ref={sideBarRef}
            className="absolute top-0 left-0 w-full prisma h-full hidden lg:block"
            style={{ height: `calc(100% - ${navTopPosition}px)` }}
          >
            <div
              ref={sideNavRef}
              className={`${
                !hasFullWidthBlock ? "sticky" : ""
              } floating-anchor top-0 container mx-auto px-6 blend-difference`}
            >
              <div className="flex flex-no-wrap gap-4 pb-20 z-auto relative">
                <div className="relative lg:w-52 prisma z-50">
                  <div>
                    <div className="border border-solid border-transparent">
                      <div className="transition-all duration-300 ease-in-out">
                        <ul>
                          {navigationItems.map((item: any, key: number) => (
                            <NavigationLink key={key} index={key} {...item}>
                              {item.expandSubNav && <AnchorNav navigationItem={item} />}
                            </NavigationLink>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {contentBlocks.map((block: any, key: number) => {
          if (!isRenderable(block, key)) return null;
          let negativeMarginTwoBlockColumn = false;
          let firstBlockRichText = false;

          if (!isSSR && key === 1) {
            const div = document.getElementById("s-" + block.sys.id);

            if (div && sideNavRef && sideNavRef.current) {
              if (div.getBoundingClientRect().height < sideNavRef.current.clientHeight) {
                div.style.paddingBottom = `${
                  (sideNavRef.current.clientHeight - div.getBoundingClientRect().height) * 1.15
                }px`;
              }
            }
          }

          if (
            block.__typename === "Contentful_BlockRichText" &&
            contentBlocks[key - 1] &&
            contentBlocks[key - 1].__typename === "Contentful_BlockTwoColumn" &&
            (contentBlocks[parseInt(String(key)) - 1] as BlockItemProps).__typename === "Contentful_BlockTwoColumn" &&
            (contentBlocks[parseInt(String(key)) - 1] as BlockItemProps)?.colourway === null
          )
            negativeMarginTwoBlockColumn = true;

          if (contentBlocks[0].__typename === "Contentful_BlockRichText") firstBlockRichText = true;

          return (
            <section
              id={`s-${block.sys.id}`}
              datatype={block.__typename}
              className={`${negativeMarginTwoBlockColumn ? "-mt-10 md:-mt-12 lg:-mt-24" : ""} ${key === 0 ? "pt-8" : ""}`}
              data-section-index={sections.indexOf(`s-${block.sys.id}`) ?? null}
              key={key}
            >
              <AnchorBlock
                {...block}
                index={key}
                hasSideNav={navigationItems?.length || false}
                firstBlockRichText={firstBlockRichText}
              />
            </section>
          );
        })}
      </div>
    </>
  );
}

export default SideNavAnchor;
