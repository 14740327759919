import gsap from "gsap";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";

interface State {
  init: boolean;
  isAudioPlaying: boolean;
  isAudioMuted: boolean;
  audioTime: number;
  audioDuration: number;
  audioLoadProgress: number;
  audioPercent: number;
  isFloatingOptionVisible: boolean;
}

function Podcast({ ...article }) {
  const [state, setState] = useState<State>({
    init: false,
    isAudioPlaying: false,
    isAudioMuted: false,
    audioTime: 0,
    audioDuration: 0,
    audioLoadProgress: 0,
    audioPercent: 0,
    isFloatingOptionVisible: true,
  });

  const numberToString = (n: number): string => {
    return n < 10 ? "0" + n.toString() : n.toString();
  };

  const timeToDuration = (time: number): string => {
    let h = Math.floor(time / 3600);
    let m = Math.floor((time % 3600) / 60);
    let s = Math.floor((time % 3600) % 60);

    if (h <= 0) {
      return `${numberToString(m)}:${numberToString(s)}`;
    }
    return `${numberToString(h)}:${numberToString(m)}:${numberToString(s)}`;
  };

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const scrubRef = useRef<HTMLDivElement | null>(null);

  const scrubBackgroundRef = useRef<HTMLDivElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const loadingRef = useRef<HTMLDivElement | null>(null);

  // const scrubFloatingBackgroundRef = useRef<HTMLDivElement | null>(null);
  const progressFloatingRef = useRef<HTMLDivElement | null>(null);
  const loadingFloatingRef = useRef<HTMLDivElement | null>(null);

  const labelFloatingRef = useRef<HTMLDivElement | null>(null);
  const labelFloatingContainerRef = useRef<HTMLDivElement | null>(null);
  const gradientRightFloatingRef = useRef<HTMLDivElement | null>(null);
  const gradientLeftFloatingRef = useRef<HTMLDivElement | null>(null);

  const isScrubMoving = useRef(false);
  const requestRef = useRef(0);

  const onLoadedMetadata = () => {
    setState((prevState) => ({
      ...prevState,
      audioDuration: audioRef.current?.duration || 0,
    }));
  };

  const onLoadProgress = () => {
    if (audioRef.current) {
      if (audioRef.current.buffered.length > 0) {
        const percent = audioRef.current.buffered.end(0) / audioRef.current.duration;
        if (loadingRef.current) {
          gsap.set(loadingRef.current, { width: `${percent * 100}%` });
        }
        if (loadingFloatingRef.current) {
          gsap.set(loadingFloatingRef.current, { width: `${percent * 100}%` });
        }
      }
    }
  };

  const onTimeUpdate = () => {
    const currentAudioTime = audioRef.current?.currentTime || 0;
    const currentDurationTime = audioRef.current?.duration || 0;

    const percent = currentAudioTime / currentDurationTime;
    if (progressRef.current) {
      if (!isScrubMoving.current) {
        gsap.set(scrubRef.current, { left: `${percent * 100}%` });
      }
      gsap.set(progressRef.current, { width: `${percent * 100}%` });
    }
    if (progressFloatingRef.current) {
      gsap.set(progressFloatingRef.current, { width: `${percent * 100}%` });
    }

    setState((prevState) => ({
      ...prevState,
      audioTime: currentAudioTime,
      audioPercent: percent,
    }));
  };

  const onPlay = () => {
    setState((prevState) => ({
      ...prevState,
      isAudioPlaying: true,
    }));
  };

  const onPause = () => {
    setState((prevState) => ({
      ...prevState,
      isAudioPlaying: false,
    }));
  };

  const onEnded = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  const onClickPlayPause = () => {
    if (audioRef.current != null) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setState((prevState) => ({
          ...prevState,
          init: true,
        }));
      } else {
        audioRef.current.pause();
      }
    }
  };

  const onClickMuteUnMute = () => {
    if (audioRef.current != null) {
      if (audioRef.current.muted) {
        audioRef.current.muted = false;
      } else {
        audioRef.current.muted = true;
      }
      setState((prevState) => ({
        ...prevState,
        isAudioMuted: audioRef.current?.muted || false,
      }));
    }
  };

  const getMousePosition = (event: any): any => {
    const touch = event.touches && event.touches[0];
    const position: any = touch ? { x: touch.clientX, y: touch.clientY } : { x: event.clientX, y: event.clientY };

    return { isTouch: touch ? true : false, position: position };
  };

  const onMouseMoveScrub = (event: any) => {
    const mouse = getMousePosition(event);

    const rect = scrubBackgroundRef.current?.getBoundingClientRect();
    if (rect) {
      let currentPosition = {
        x: (mouse.position.x - rect.left) / rect.width,
        y: -((mouse.position.y - rect.top) / rect.height),
      };

      currentPosition.x = currentPosition.x < 0 ? 0 : currentPosition.x;
      currentPosition.x = currentPosition.x > 1 ? 1 : currentPosition.x;

      if (audioRef.current) {
        audioRef.current.currentTime = currentPosition.x * audioRef.current?.duration;
      }

      gsap.set(scrubRef.current, { left: `${currentPosition.x * 100}%` });
    }
  };

  const onMouseUpScrub = (event: any) => {
    window.removeEventListener("touchend", onMouseUpScrub);
    window.removeEventListener("touchmove", onMouseMoveScrub);
    window.removeEventListener("mouseup", onMouseUpScrub);
    window.removeEventListener("mousemove", onMouseMoveScrub);

    if (audioRef.current) {
      audioRef.current.play();
    }
    isScrubMoving.current = false;
  };

  const onMouseDownScrub = (event: any) => {
    if (state.init) {
      audioRef.current?.pause();
      isScrubMoving.current = true;
      window.addEventListener("touchend", onMouseUpScrub);
      window.addEventListener("touchmove", onMouseMoveScrub);
      window.addEventListener("mouseup", onMouseUpScrub);
      window.addEventListener("mousemove", onMouseMoveScrub);
    }
  };

  const onClickScrubBackground = (event: any) => {
    event.preventDefault();
    const mouse = getMousePosition(event);

    const rect = event.currentTarget.getBoundingClientRect();
    if (rect) {
      let currentPosition = {
        x: (mouse.position.x - rect.left) / rect.width,
        y: -((mouse.position.y - rect.top) / rect.height),
      };

      currentPosition.x = currentPosition.x < 0 ? 0 : currentPosition.x;
      currentPosition.x = currentPosition.x > 1 ? 1 : currentPosition.x;

      if (audioRef.current) {
        audioRef.current.currentTime = currentPosition.x * audioRef.current?.duration;
        audioRef.current.play();

        setState((prevState) => ({
          ...prevState,
          init: true,
        }));
      }
      if (scrubRef.current) {
        gsap.set(scrubRef.current, { left: `${currentPosition.x * 100}%` });
      }
    }
  };

  // const onClickClose = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isFloatingOptionVisible: false,
  //   }));
  // };

  const animate = () => {
    onLoadProgress();
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [state.isAudioPlaying]);

  useEffect(() => {
    if (labelFloatingContainerRef.current && labelFloatingRef.current) {
      const containerRect = labelFloatingContainerRef.current?.getBoundingClientRect();
      const labelRect = labelFloatingRef.current?.getBoundingClientRect();
      const offsetX = (labelRect.width - containerRect.width) * -1 - 5;
      const speed = labelRect.width / 50;
      const delay = 2;
      const yoyo = true;

      if (labelRect?.width > containerRect?.width) {
        gsap.fromTo(
          labelFloatingRef.current,
          { x: 0 },
          {
            x: offsetX,
            duration: speed,
            ease: "none",
            delay: delay,
            repeat: -1,
            yoyo: yoyo,
            repeatDelay: delay,
          }
        );
        gsap.fromTo(
          gradientRightFloatingRef.current,
          { opacity: 1 },
          {
            opacity: 0,
            duration: speed,
            ease: "none",
            delay: delay,
            repeat: -1,
            yoyo: yoyo,
            repeatDelay: delay,
          }
        );
        gsap.fromTo(
          gradientLeftFloatingRef.current,
          { opacity: 0 },
          {
            opacity: 1,
            duration: speed,
            ease: "none",
            delay: delay,
            repeat: -1,
            yoyo: yoyo,
            repeatDelay: delay,
          }
        );
      }
    }
    return () => {
      gsap.killTweensOf([labelFloatingRef.current, gradientLeftFloatingRef.current, gradientRightFloatingRef.current]);
    };
  }, [state.isFloatingOptionVisible]);

  const url = get(article, "podcast.file.url") ?? null;
  if (!url) return null;

  return (
    <section className="select-none">
      <audio
        ref={audioRef}
        src={url}
        preload={"metadata"}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
      ></audio>
      <div className="bg-purple-400 text-white font-medium text-base">
        <div className="mx-auto">
          <div className="relative w-full h-1">
            <div className="relative w-full h-full bg-white opacity-50"></div>
            <div ref={loadingRef} className="absolute top-0 left-0 right-0 bottom-0 w-0 h-full bg-white opacity-50"></div>
            <div ref={progressRef} className="absolute top-0 left-0 right-0 bottom-0 w-0 h-full bg-white"></div>
            <div
              ref={scrubBackgroundRef}
              onClick={onClickScrubBackground}
              className="absolute top-0 left-0 right-0 bottom-0"
            ></div>
            <div
              ref={scrubRef}
              onMouseDown={onMouseDownScrub}
              onTouchStart={onMouseDownScrub}
              className="absolute top-1/2 left-0"
            >
              <div
                className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-purple-500 cursor-pointer"
                style={{
                  width: 24,
                  height: 24,
                }}
              ></div>
            </div>
          </div>
          <div className="flex justify-between w-full py-4">
            <div>
              <p>{timeToDuration(state.audioTime)}</p>
            </div>
            <div>
              <p>{timeToDuration(state.audioDuration)}</p>
            </div>
          </div>

          <div className="flex justify-between items-center w-full py-4 pb-0">
            <div className="w-1/3">
              <button className="cursor-pointer" onClick={onClickMuteUnMute}>
                <div>
                  {!state.isAudioMuted && (
                    <SVG src="/images/icons/sound-on.svg" width="31" height="24" title="Mute" cacheRequests={true} />
                  )}
                  {state.isAudioMuted && (
                    <SVG src="/images/icons/sound-off.svg" width="31" height="24" title="Mute" cacheRequests={true} />
                  )}
                </div>
              </button>
            </div>
            <div className="w-1/3">
              <div className="relative flex items-center justify-center ">
                <button
                  className="bg-purple-500 flex items-center justify-center cursor-pointer rounded-full"
                  onClick={onClickPlayPause}
                  style={{
                    width: 96,
                    height: 96,
                  }}
                >
                  {!state.isAudioPlaying && (
                    <SVG
                      src="/images/icons/play.svg"
                      width="32"
                      height="40"
                      title="Play"
                      className="ml-2"
                      cacheRequests={true}
                    />
                  )}
                  {state.isAudioPlaying && (
                    <SVG src="/images/icons/pause.svg" width="32" height="40" title="Play" cacheRequests={true} />
                  )}
                </button>
              </div>
            </div>
            <div className="w-1/3"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Podcast);
