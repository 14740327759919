import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import React from "react";
import { Contentful_BlockHero, Contentful_Link, Contentful_SiteSettings, Maybe } from "graphql-types";
import ReactPlayer from "react-player/lazy";
import InlineSVG from "../inline-svg";
import Image from "../image";
import Button from "../button";
import Heading1 from "../rich-text-elements/heading-1";
import Paragraph from "../rich-text-elements/paragraph";

function Buttons({ buttons }: { buttons: Maybe<Contentful_Link>[] }) {
  if (!buttons) return;
  return (
    <div className="lg:flex">
      {buttons.map((button, key) => {
        if (button)
          return (
            <div key={key} className="mb-3 last:mb-0 lg:mb-0 lg:mr-3">
              <Button {...button} />
            </div>
          );

        return null;
      })}
    </div>
  );
}

function Hero(props: Contentful_BlockHero & { sidebar?: boolean }) {
  if (!props.sys) return null;

  const data = useStaticQuery(heroQuery);

  const { taglineLockup }: Contentful_SiteSettings = get(data, "contentful.siteSettingsCollection.items.0");

  const size =
    (props.backgroundImage || props.backgroundVideo) && props.useTaglineLockup
      ? "hero-full"
      : (props.backgroundImage || props.backgroundVideo) && props.heading
      ? "hero-medium"
      : (props.backgroundImage || props.backgroundVideo) && !props.heading
      ? "hero-small"
      : "hero-minimal";

  const opacityOverlayStyle = props.overlayOpacity ? { opacity: props.overlayOpacity / 100 } : { opacity: 0 };

  const theme = size === "hero-minimal" ? "text-slate-400" : "text-white";
  const isTaglineStyle = props.useTaglineLockup;

  function Content() {
    if (isTaglineStyle) {
      return (
        <>
          {taglineLockup && <InlineSVG image={taglineLockup} className="lockup inline-block mb-6" />}
          {props.linksCollection && props.linksCollection.items.length > 0 && (
            <Buttons buttons={props.linksCollection.items} />
          )}
        </>
      );
    }

    const wrapClasses = "mb-12 lg:ml-24 lg:mb-16";
    // const wrapClasses = "";
    // TODO::Check why this was created
    // const wrapClasses = props.sidebar ? "mb-12 lg:ml-24 lg:mb-16" : "";
    // TODO::Check why this was created
    // const wrapClasses = props.sidebar ? "mb-12 lg:ml-24 lg:mb-16" : "";

    return (
      <div className={wrapClasses}>
        {props.subHeading && <Paragraph className="font-medium">{props.subHeading}</Paragraph>}
        {props.heading && <Heading1 className="text-5xl md:text-6xl lg:text-7xl xl:text-7xl mb-0">{props.heading}</Heading1>}
        {props.linksCollection && props.linksCollection.items.length > 0 && (
          <Buttons buttons={props.linksCollection.items} />
        )}
      </div>
    );
  }

  function Background() {
    if (!props.backgroundImage && !props.backgroundVideo) return null;

    // Get the Vimeo ID from the URL
    let iframeSrc = null;
    const match =
      props.backgroundVideo &&
      props.backgroundVideo.match(/^(https:\/\/vimeo.com\/|https:\/\/www.youtube.com\/watch\?v=)[a-z0-9]+$/i);
    if (match) iframeSrc = props.backgroundVideo;

    return (
      <div className="absolute inset-0 pointer-events-none">
        <div className="bg-black absolute inset-0 pointer-events-none z-20" style={opacityOverlayStyle} />
        {props.backgroundImage && props.backgroundImage.image && (
          <div className="absolute inset-0">
            <Image
              image={props.backgroundImage.image}
              alt={props.backgroundImage.image.title ?? ""}
              className="bg-image w-full h-full"
              maxWidth={4000}
              type={"fluid"}
              style={{ position: "absolute" }}
              loading="eager"
              objectFit="cover"
              focalPoint={props.backgroundImage.focalPoint}
            />
          </div>
        )}

        {props.backgroundVideo && iframeSrc && (
          <ReactPlayer
            url={iframeSrc}
            loop={true}
            controls={true}
            muted={true}
            playing={true}
            playsinline={true}
            className="bg-video"
            config={{
              youtube: {
                playerVars: { showinfo: 0 },
              },
            }}
            style={{ position: "absolute" }}
          />
        )}
      </div>
    );
  }

  return (
    <section className={`theme-element flex relative overflow-hidden ${theme} ${size}`}>
      <h3 className="sr-only">{(props.useTaglineLockup && taglineLockup?.title) || props.heading}</h3>
      <Background />
      <div
        className={`container mx-auto flex flex-col h-full px-6 z-20 ${isTaglineStyle ? "justify-center" : "justify-end"}`}
      >
        <Content />
      </div>
    </section>
  );
}

// export default Hero;
export default React.memo(Hero);

const heroQuery = graphql`
  query {
    contentful {
      siteSettingsCollection(limit: 1) {
        items {
          taglineLockup {
            title
            url
          }
        }
      }
      entryCollection {
        items {
          ... on Contentful_BlockHero {
            includeLocationCollection(limit: 3) {
              items {
                code
              }
            }
            excludeLocationCollection(limit: 3) {
              items {
                code
              }
            }
          }
        }
      }
    }
  }
`;
