type SpaceMeasures = "default" | "none" | "sm" | "md" | " lg" | "xl";

export interface BlockSpaceJson {
  mobile: SpaceMeasures;
  desktop: SpaceMeasures;
  tablet: SpaceMeasures;
}
export interface SpacerDefaultProps {
  sm: number;
  md: number;
  lg: number;
}

export const translateBlockSpacing = (
  direction: "bottom" | "top",
  space: BlockSpaceJson,
  defaultOptions: SpacerDefaultProps
) => {
  let finalClass = "";

  const mobileSpacing =
    !space || space.mobile === "default"
      ? `p${direction[0]}-${defaultOptions.sm}`
      : `p${direction[0]}-${space.mobile.replace("none", "0").toLowerCase()}`;
  const tableSpacing =
    !space || space.tablet === "default"
      ? `md:p${direction[0]}-${defaultOptions.md}`
      : `md:p${direction[0]}-${space.tablet.replace("none", "0").toLowerCase()}`;

  const desktopSpacing =
    !space || space.desktop === "default"
      ? `lg:p${direction[0]}-${defaultOptions.lg}`
      : `lg:p${direction[0]}-${space.desktop.replace("none", "0").toLowerCase()}`;

  if (mobileSpacing) {
    finalClass = finalClass.concat(" ", mobileSpacing);
  }

  if (tableSpacing) {
    finalClass = finalClass.concat(" ", tableSpacing);
  }

  if (desktopSpacing) {
    finalClass = finalClass.concat(" ", desktopSpacing);
  }

  return finalClass;
};
