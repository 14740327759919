import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";

const SectorsCarouselNavProgress = ({
  activeIndex,
  duration,
  color,
  onComplete,
}: {
  activeIndex: number;
  duration: number;
  color: string;
  onComplete: any;
}) => {
  interface State {
    isActive: boolean;
  }
  const [state, setState] = useState<State>({
    isActive: false,
  });

  const checkViewportVisibility = (elm: any): boolean => {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  };

  const bar = useRef(null);
  useEffect(() => {
    if (state.isActive) {
      if (bar.current) {
        if (checkViewportVisibility(bar.current)) {
          gsap.fromTo(
            bar.current,
            { width: "0%" },
            {
              duration: duration,
              width: "100%",
              ease: "none",
              onComplete: onComplete,
            }
          );
        }
      }
    }
    return () => {
      gsap.killTweensOf(bar.current);
    };
  }, [state.isActive, activeIndex]);

  const onScroll = () => {
    if (bar.current) {
      const isElementVisible = checkViewportVisibility(bar.current);
      if (isElementVisible) {
        if (!state.isActive) {
          setState((prevState) => ({
            ...prevState,
            isActive: true,
          }));
        }
      } else {
        if (state.isActive) {
          setState((prevState) => ({
            ...prevState,
            isActive: false,
          }));
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <>
      <div className="relative w-full overflow-hidden" data-index={activeIndex}>
        <div className={`relative w-full h-1 opacity-25 ${color}`}></div>
        <div ref={bar} className={`absolute top-0 left-0 w-0 h-1 ${color}`}></div>
      </div>
    </>
  );
};

export default SectorsCarouselNavProgress;
