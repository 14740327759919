import React from "react";
import { Contentful_BlockRichText } from "graphql-types";
import classnames from "classnames";
import { default as RichTextContent } from "../rich-text";
import { BlockSpaceJson, SpacerDefaultProps, translateBlockSpacing } from "../../utils/translate-cf-spacing";

function RichText(props: Contentful_BlockRichText) {
  if (!props.sys) return null;

  const isTight = props.isTight ?? false;

  const topSpaceDefault: SpacerDefaultProps = {
    sm: isTight ? 3 : 6,
    md: isTight ? 6 : 12,
    lg: isTight ? 8 : 24,
  };

  const bottomSpace: SpacerDefaultProps = {
    sm: isTight ? 3 : 6,
    md: isTight ? 6 : 12,
    lg: isTight ? 8 : 24,
  };
  const topSpaceClasses = translateBlockSpacing("top", props.topSpace as BlockSpaceJson, topSpaceDefault);
  const bottomSpaceClasses = translateBlockSpacing("bottom", props.bottomSpace as BlockSpaceJson, bottomSpace);

  return (
    <section className={classnames(`container mx-auto px-6 ${topSpaceClasses} ${bottomSpaceClasses}`, {})}>
      <div>{props.content && <RichTextContent {...props.content} isBlock={true} />}</div>
    </section>
  );
}

// export default RichText;
export default React.memo(RichText);
