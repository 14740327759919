export const findSideNavItems = (nodes: any, pageId: string) => {
  return nodes.find((node: any) => {
    if (!node?.internalPage?.sys.id) {
      return false;
    }
    if (node?.internalPage?.sys.id === pageId) {
      return true;
    } else if (node?.childrenCollection?.items?.length > 0) {
      return findSideNavItems(node?.childrenCollection.items, pageId);
    }
  });
};

export const filterDataByLocation = <T>(data: any, location: string) => {
  if (!data) return null;

  const includeItems = data?.includeLocationCollection?.items || [];
  const excludeItems = data?.excludeLocationCollection?.items || [];

  if (includeItems.length === 0 && excludeItems.length === 0) return data;

  if (includeItems.length > 0) {
    const include = includeItems.find((item: any) => item.code === location);
    if (!include) return null;
  }

  if (excludeItems.length > 0) {
    const exclude = excludeItems.find((item: any) => item.code === location);
    if (exclude) return null;
  }

  return <T>data;
};
