import React from "react";
import get from "lodash/get";
import { graphql, useStaticQuery } from "gatsby";
import { Contentful_Asset } from "graphql-types";
import Image from "../image";

function Asset({ id }: { id: string }) {
  const data = useStaticQuery(assetsQuery);
  const items: Contentful_Asset[] = get(data, "contentful.assetCollection.items");
  const asset = items.find((item: Contentful_Asset) => item.sys.id === id);
  const imageMimeTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  if (!asset) return null;

  // If we have an image
  if (imageMimeTypes.includes(asset?.contentType ?? "")) {
    return (
      <div className="full-width">
        <div className="lg:w-2/3 xl:w-auto xl:max-w-screen-lg lg:ml-auto">
          <div className="w-full py-6 md:py-12 lg:py-24">
            <Image
              type="fluid"
              image={asset}
              alt={asset.title ?? ""}
              title={asset.title ?? ""}
              maxWidth={2000}
              className="w-full"
            />
            {asset.description && <div className="container mx-auto px-4 text-sm pt-2">{asset.description}</div>}
          </div>
        </div>
      </div>
    );
  } else if (asset?.url)
    return (
      <p className="mb-4 last:mb-0 lg:ml-64 lg:max-w-screen-md">
        <a href={asset.url} className="underline" target="_blank">
          {asset.title && asset.title}
          {!asset.title && asset.url}
        </a>
        {asset.description && <div className="">{asset.description}</div>}
      </p>
    );

  return null;
}

// export default Image;
export default React.memo(Asset);

const assetsQuery = graphql`
  query AssetsQuery {
    contentful {
      assetCollection(limit: 1000) {
        items {
          ...imageFields
        }
      }
    }
  }
`;
