import React from "react";
import classnames from "classnames";

function Heading1({ className, children }: { className: string; children: React.ReactNode }) {
  return (
    <h1 className={classnames(["text-5xl font-medium leading-tight lg:text-6xl xl:text-7xl mb-6 last:mb-0", className])}>
      {children}
    </h1>
  );
}

Heading1.defaultProps = {
  className: "",
};

// export default Heading1;
export default React.memo(Heading1);
