import React, { useContext } from "react";
import get from "lodash/get";
import parse from "html-react-parser";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import BlockLoader from "../components/block-loader";
import Button from "../components/button";
import { default as InlineButton } from "../components/rich-text-elements/button";
import Asset from "../components/rich-text-elements/asset";
import Heading1 from "../components/rich-text-elements/heading-1";
import Heading2 from "../components/rich-text-elements/heading-2";
import Heading3 from "../components/rich-text-elements/heading-3";
import Heading4 from "../components/rich-text-elements/heading-4";
import Heading5 from "../components/rich-text-elements/heading-5";
import Heading6 from "../components/rich-text-elements/heading-6";
import Paragraph from "../components/rich-text-elements/paragraph";
import Quote from "../components/rich-text-elements/quote";
import ImageWithFocalPoint from "../components/rich-text-elements/image-with-focal-point";
import { SideNavContext } from "../contexts/SideNavContext";
import { textRender } from "../utils/text-render";

function options(
  forceCurrentColor: boolean,
  links: any,
  wrapClassName: string,
  isBlock: boolean,
  forceLargeButton: boolean,
  highlightColorClassName: string
) {
  const HasSideNav = useContext(SideNavContext);
  const sideNavWrapClassName = `${HasSideNav && isBlock ? "lg:ml-64 lg:max-w-screen-md" : ""} ${wrapClassName}`;

  const Wrap = ({ children, ...props }: any) => (
    <span className={wrapClassName} {...props}>
      {children}
    </span>
  );

  return {
    renderText: (text: string) => {
      return text.split("\n").map((t, i) =>
        i > 0 ? (
          <React.Fragment key={i}>
            <br />
            {textRender(t)}
          </React.Fragment>
        ) : (
          textRender(t)
        )
      );
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading1 className={sideNavWrapClassName}>{children}</Heading1>;
      },
      [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading2 className={sideNavWrapClassName}>{children}</Heading2>;
      },
      [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading3 className={`mb-2 last:mb-0 ${sideNavWrapClassName}`}>{children}</Heading3>;
      },
      [BLOCKS.HEADING_4]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading4 className={`mb-2 last:mb-0 ${sideNavWrapClassName}`}>{children}</Heading4>;
      },
      [BLOCKS.HEADING_5]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading5 className={`mb-2 last:mb-0 ${sideNavWrapClassName}`}>{children}</Heading5>;
      },
      [BLOCKS.HEADING_6]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "") return null;
        return <Heading6 className={`mb-2 last:mb-0 ${sideNavWrapClassName}`}>{children}</Heading6>;
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "" && !children) return null;
        if (documentToPlainTextString(node).trim() === "") return children;
        return <Paragraph className={`mb-4 last:mb-0 ${sideNavWrapClassName}`}>{children}</Paragraph>;
      },
      [BLOCKS.QUOTE]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "" && !children) return null;
        if (documentToPlainTextString(node).trim() === "") return children;
        return <Quote className={`mt-6 mb-6 last:mb-0 ${sideNavWrapClassName}`}>{children}</Quote>;
      },
      [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "" && !children) return null;
        if (documentToPlainTextString(node).trim() === "") return children;
        return (
          <div className={`${sideNavWrapClassName} mb-4 last:mb-0`}>
            <ul className="ml-6 list-disc">{children}</ul>
          </div>
        );
      },
      [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "" && !children) return null;
        if (documentToPlainTextString(node).trim() === "") return children;
        return (
          <li className="rich-text-list-item">
            <span className={`${HasSideNav && isBlock ? "lg:-ml-64 block" : "block"}`}>{children}</span>
          </li>
        );
      },
      [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode) => {
        if (documentToPlainTextString(node).trim() === "" && !children) return null;
        if (documentToPlainTextString(node).trim() === "") return children;
        return (
          <div className={`${sideNavWrapClassName} mb-4 last:mb-0`}>
            <ol className="ml-6 list-decimal">{children}</ol>
          </div>
        );
      },
      [BLOCKS.HR]: () => {
        return (
          <div className={sideNavWrapClassName}>
            <Wrap>
              <hr className="my-6 border-gray-400" />
            </Wrap>
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        if (get(node, "data.target.sys.linkType") === "Asset") return <Asset id={node.data.target.sys.id} />;
        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
        const id = get(node, "data.target.sys.id");

        // We only support button/link embeds here..
        if (!links)
          return (
            <div className={`mb-4 last:mb-0 ${sideNavWrapClassName}`}>
              <ImageWithFocalPoint id={id} />
              <InlineButton id={id} forceCurrentColor={forceCurrentColor} forceLargeButton={forceLargeButton} />
            </div>
          );

        const link = links.entries.block.find((block: any) => block.sys?.id === id);
        if (!link || link.__typename === "Contentful_ImageWithFocalPoint")
          return (
            <div className={`${sideNavWrapClassName}`}>
              <ImageWithFocalPoint id={id} />
            </div>
          );

        if (link.__typename === "Contentful_Link")
          return (
            <div className={`container mx-auto px-6 mt-6 mb-4 ${sideNavWrapClassName}`}>
              <Button {...link} forceCurrentColor={forceCurrentColor} forceLargeButton={forceLargeButton} />
            </div>
          );

        if (link.__typename === "Contentful_BlockVideo")
          return (
            <div className={`${sideNavWrapClassName}`}>
              <BlockLoader blocks={[link]} hasSideNavigation={false} />
            </div>
          );

        if (link.__typename === "Contentful_BlockInfographic")
          return (
            <div className={`${sideNavWrapClassName}`}>
              <BlockLoader blocks={[link]} hasSideNavigation={false} />
            </div>
          );

        return <BlockLoader blocks={[link]} hasSideNavigation={false} />;
      },
      [INLINES.HYPERLINK]: (node: any) => {
        const href = node.data?.uri;
        if (documentToPlainTextString(node).trim() === "" || !href) return null;
        return (
          <a href={href} target="_blank" rel="noreferrer noopener" className="underline transition-opacity hover:opacity-80">
            {documentToPlainTextString(node).trim()}
          </a>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        const id = get(node, "data.target.sys.id");

        // We only support button/link embeds here..
        if (!links)
          return <InlineButton id={id} forceCurrentColor={forceCurrentColor} forceLargeButton={forceLargeButton} />;

        const link = links.entries.inline.find((inline: any) => inline.sys?.id === id);
        if (!link) return null;

        if (link.__typename === "Contentful_Link")
          return <Button {...link} forceCurrentColor={forceCurrentColor} forceLargeButton={forceLargeButton} />;

        return (
          <div className={sideNavWrapClassName}>
            <BlockLoader blocks={[link]} hasSideNavigation={false} />
          </div>
        );
      },
    },
  };
}

function RichText({
  json,
  links,
  forceCurrentColor,
  wrapClassName,
  isBlock = false,
  forceLargeButton = false,
  highlightColorClassName = "",
}: {
  json: any;
  links: any;
  forceCurrentColor: boolean;
  wrapClassName: string;
  forceLargeButton?: boolean;
  isBlock?: boolean;
  highlightColorClassName: string;
}) {
  return (
    <>
      {documentToReactComponents(
        json,
        options(forceCurrentColor, links, wrapClassName, isBlock, forceLargeButton, highlightColorClassName)
      )}
    </>
  );
}

RichText.defaultProps = {
  forceCurrentColor: false,
  wrapClassName: "",
  highlightColorClassName: "",
};

// export default RichText;
export default React.memo(RichText);
