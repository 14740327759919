import React from "react";
import { Field } from "formik";
import { ErrorDisplay } from "../forms/utils";

interface Props {
  label: string;
  name: string;
  type?: string;
  formProps?: any;
  [k: string]: any;
}

export default function ({ label, name, type = "text", formProps, ...props }: Props) {
  return (
    <div>
      <label className="relative flex flex-row items-center">
        <Field
          type={type}
          name={name}
          placeholder=" "
          className="disabled:opacity-50 floating bg-transparent text-lg border-0 border-b-2 block w-full focus:border-slate-300 focus:ring focus:ring-transparent px-0"
          disabled={formProps.isSubmitting}
          {...props}
        />
        <span className="absolute left-0 pointer-events-none duration-300 opacity-70 origin-0 text-lg leading-tight">
          {label}
        </span>
      </label>
      {formProps && formProps.errors[name] && formProps.touched[name] ? (
        <ErrorDisplay>{formProps.errors[name]}</ErrorDisplay>
      ) : null}
    </div>
  );
}
