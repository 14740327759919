import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { Contentful_BlockSectorsCarouselItem, Maybe } from "graphql-types";
import Button from "../button";
import Image from "../image";
import classnames from "classnames";

interface SectorsCarouselHeroStyles {
  primaryStyle: string;
  secondaryStyle: string;
  textStyle: string;
  foregroundStyle: string;
}

const SectorsCarouselHero = ({
  item,
  isAvailable,
  foregroundColor,
}: {
  item: Contentful_BlockSectorsCarouselItem;
  isAvailable: boolean;
  foregroundColor: string;
}) => {
  interface State {
    title: Maybe<string>;
    blockStyle: SectorsCarouselHeroStyles | null;
  }
  const [state, setState] = useState<State>({
    title: "",
    blockStyle: null,
  });

  const blockStyles: Array<SectorsCarouselHeroStyles> = [
    {
      primaryStyle:
        "-translate-x-12 -translate-y-4 md:-translate-x-32 md:-translate-y-20 lg:-translate-x-40 lg:-translate-y-16",
      secondaryStyle:
        "translate-x-16 translate-y-16 md:translate-x-32 md:translate-y-24 lg:translate-x-48 lg:translate-y-24",
      textStyle: "translate-x-20 -translate-y-16 md:translate-x-56 md:-translate-y-32 lg:translate-x-64 lg:-translate-y-32",
      foregroundStyle: "sectors-carousel-overlay-offset-1",
    },
    {
      primaryStyle:
        "-translate-x-8 -translate-y-12 md:-translate-x-12 md:-translate-y-20 lg:-translate-x-32 lg:-translate-y-16",
      secondaryStyle: "translate-x-20 translate-y-12 md:translate-x-40 md:translate-y-18 lg:translate-x-56 lg:translate-y-4",
      textStyle: "-translate-x-20 translate-y-16 md:-translate-x-56 md:translate-y-32 lg:-translate-x-64 lg:translate-y-24",
      foregroundStyle: "sectors-carousel-overlay-offset-2",
    },
    {
      primaryStyle: "-translate-x-8 translate-y-8 md:-translate-x-12 md:translate-y-20 lg:-translate-x-32 lg:translate-y-16",
      secondaryStyle:
        "translate-x-20 -translate-y-12 md:translate-x-40 md:-translate-y-24 lg:translate-x-56 lg:-translate-y-12",
      textStyle:
        "-translate-x-20 -translate-y-16 md:-translate-x-56 md:-translate-y-32 lg:-translate-x-64 lg:-translate-y-40",
      foregroundStyle: "sectors-carousel-overlay-offset-3",
    },
    {
      primaryStyle:
        "-translate-x-12 -translate-y-4 md:-translate-x-32 md:translate-y-20 lg:-translate-x-40 lg:translate-y-16",
      secondaryStyle:
        "translate-x-16 -translate-y-16 md:translate-x-32 md:-translate-y-24 lg:translate-x-48 lg:-translate-y-24",
      textStyle: "translate-x-20 translate-y-16 md:translate-x-56 md:translate-y-32 lg:translate-x-64 lg:translate-y-32",
      foregroundStyle: "sectors-carousel-overlay-offset-4",
    },
  ];

  const currentBlockStyle: SectorsCarouselHeroStyles = blockStyles[Math.floor(blockStyles.length * Math.random())];

  const container = useRef(null);

  const primaryImageContainer = useRef(null);
  const primaryImageBackgroundAsset = useRef(null);
  const primaryImageBackground = useRef(null);

  const secondaryImageContainer = useRef(null);
  const secondaryImageBackground = useRef(null);
  const secondaryImageBackgroundAsset = useRef(null);

  const foregroundContainer = useRef(null);
  const buttonContainer = useRef(null);
  const titleContainer = useRef(null);
  const titleText = useRef(null);

  const titleBubble = useRef(null);
  const primaryImageBubble = useRef(null);
  const secondaryImageBubble = useRef(null);
  const foregroundBubble = useRef(null);

  const rotate = (cx: number, cy: number, x: number, y: number, angle: number) => {
    let radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return { x: nx, y: ny };
  };

  const animation: any = {
    angle: 0,
  };

  useEffect(() => {
    if (!isAvailable) {
      gsap.set(container.current, { display: "none" });
    } else {
      gsap.set(container.current, { display: "block" });

      gsap.fromTo(
        primaryImageContainer.current,
        { scale: 0, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0 }
      );
      gsap.fromTo(
        primaryImageBackground.current,
        { scale: 3, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0 }
      );
      gsap.fromTo(
        foregroundContainer.current,
        { scale: 1.2, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0 }
      );
      gsap.fromTo(
        secondaryImageContainer.current,
        { scale: 0, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0.1 }
      );
      gsap.fromTo(
        secondaryImageBackground.current,
        { scale: 3, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0.1 }
      );
      gsap.fromTo(
        titleContainer.current,
        { scale: 0, force3D: true },
        { scale: 1, duration: 1, ease: "power3.out", delay: 0.2 }
      );
      gsap.fromTo(
        titleText.current,
        { scale: 0.6, opacity: 0, force3D: true },
        { scale: 1, opacity: 1, duration: 0.6, ease: "power3.out", delay: 0.4 }
      );
      gsap.fromTo(
        buttonContainer.current,
        { scale: 0.6, opacity: 0, force3D: true },
        { scale: 1, opacity: 1, duration: 0.6, ease: "power3.out", delay: 0.3 }
      );

      gsap.fromTo(
        animation,
        { angle: 0 },
        {
          angle: 360,
          duration: 30,
          repeat: -1,
          ease: "none",
          onUpdate: () => {
            let currentRotation = rotate(0, 0, 10, 10, animation.angle + -90);
            gsap.set(primaryImageBubble.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
              force3D: true,
              rotation: 1,
            });
            currentRotation = rotate(0, 0, 20, 20, animation.angle + 90);
            gsap.set(primaryImageBackgroundAsset.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
              force3D: true,
              rotation: 1,
            });
            currentRotation = rotate(0, 0, 10, 10, animation.angle + 90);
            gsap.set(secondaryImageBubble.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
              force3D: true,
              rotation: 1,
            });
            currentRotation = rotate(0, 0, 20, 20, animation.angle - 90);
            gsap.set(secondaryImageBackgroundAsset.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
              force3D: true,
              rotation: 1,
            });
            currentRotation = rotate(0, 0, 20, 20, animation.angle - 90);
            gsap.set(foregroundBubble.current, {
              x: currentRotation.x,
              y: currentRotation.y,
              z: 0,
              force3D: true,
              rotation: 1,
            });
          },
        }
      );

      setState((prevState) => ({
        ...prevState,
        title: item.label ?? "",
        blockStyle: currentBlockStyle,
      }));
    }

    return () => {
      gsap.killTweensOf([
        container.current,
        primaryImageContainer.current,
        primaryImageBackground.current,
        secondaryImageContainer.current,
        secondaryImageBackground.current,
        foregroundContainer.current,
        titleContainer.current,
        titleText.current,
        buttonContainer.current,
        titleBubble.current,
        primaryImageBubble.current,
        secondaryImageBubble.current,
        foregroundBubble.current,
        animation,
      ]);
    };
  }, [item, isAvailable]);

  if (!item) return null;

  return (
    <section ref={container} className="relative w-full hidden overflow-x-hidden">
      <h3 className="sr-only">Our sectors</h3>
      <div className="container relative mx-auto h-full px-6 z-10 text-center gpu">
        <div className="relative block w-full sectors-carousel-hero">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="relative" ref={primaryImageBubble}>
              <div ref={primaryImageContainer} className="relative">
                <div
                  className={`relative sectors-carousel-hero-left rounded-full overflow-hidden inline-block bg-cover bg-center transform ${state.blockStyle?.primaryStyle}`}
                >
                  <div ref={primaryImageBackground} className="absolute top-0 left-0 w-full h-full">
                    <div ref={primaryImageBackgroundAsset} className="absolute sectors-carousel-hero-image">
                      {item.primaryImage && (
                        <Image
                          image={item.primaryImage}
                          alt={item.primaryImage.title ?? ""}
                          className="absolute inset-0 pointer-events-none z-0"
                          maxWidth={1000}
                          type={"fluid"}
                          style={{ position: "absolute" }}
                          loading="eager"
                          fadeIn={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="relative" ref={secondaryImageBubble}>
              <div ref={secondaryImageContainer} className="relative">
                <div
                  className={`relative sectors-carousel-hero-right rounded-full overflow-hidden inline-block transform ${state.blockStyle?.secondaryStyle}`}
                >
                  <div ref={secondaryImageBackground} className="absolute top-0 left-0 w-full h-full">
                    <div ref={secondaryImageBackgroundAsset} className="absolute sectors-carousel-hero-image">
                      {item.secondaryImage && (
                        <Image
                          image={item.secondaryImage}
                          alt={item.secondaryImage.title ?? ""}
                          className="absolute inset-0 pointer-events-none z-0"
                          maxWidth={1000}
                          type={"fluid"}
                          style={{ position: "absolute" }}
                          loading="eager"
                          fadeIn={false}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`relative sectors-carousel-hero-right-overlay inline-block ${state.blockStyle?.foregroundStyle}`}
                  >
                    <div ref={foregroundBubble} className="absolute top-0 left-0 w-full h-full">
                      <div
                        ref={foregroundContainer}
                        className={`absolute top-0 left-0 w-full h-full overflow-hidden rounded-full ${foregroundColor}`}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="relative transform translate-z-0">
              <div className="relative" ref={titleContainer}>
                <div className={`sectors-carousel-hero-text inline-block transform ${state.blockStyle?.textStyle}`}>
                  <div
                    ref={titleBubble}
                    className={`absolute top-0 left-0 w-full h-full rounded-full ${foregroundColor}`}
                  ></div>
                  <div className="relative flex h-full w-full text-white items-center justify-center font-medium text-xl md:text-4xl lg:md:text-6xl leading-none">
                    <p className="p-2" ref={titleText}>
                      {state.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative md:absolute bottom-0 mb-10 w-full text-center z-10">
        <div ref={buttonContainer} className="relative">
          {item.button && <Button {...item.button} />}
        </div>
      </div>
    </section>
  );
};

export default SectorsCarouselHero;
